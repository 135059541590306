<template>
  <div
    class="search-results-card"
    v-bind:class="cardClasses"
    @mouseover="cardHover = true"
    @mouseleave="cardHover = false"
  >
    <!--
      Web Buy button taken off srp page because of the buttons fighting with each other 
      WebBuy has been removed from Honda and Truck center, contract ended 12.1.22 Christopher Garrick
    -->
    <!-- <div class="webBuyButton">
      <webbuy-button-async
        :vin="car.vin"
        :year="car.year"
        :make="car.make"
        :model="car.model"
        :condition="car.new ? 'new' : 'false'"
        :milage="car.milage"
        :pricemsrp="car.msrp ? car.msrp : car.price"
        :pricefinal="car.price"
        :priceinternet="car.price"
        :photo="car.photos[0]"
        :body="car.body"
        :trim="car.trim"
        :doors="car.door_count"
        :stocknumber="car.stock_number"
        :fuelcity="car.city_mpg"
        :fuelhighway="car.highway_mpg"
      ></webbuy-button-async>
    </div> -->
    <div
      class="details-disclaimer"
      v-if="withDetails"
      v-bind:class="{ hover: detailsHover }"
      @mouseover="detailsHover = true"
      @mouseleave="detailsHover = false"
    >
      <a
        class="show-details-disclaimer"
        type="button"
        data-target="#details-disclaimer-modal"
        data-toggle="modal"
        tabindex="0"
      >
        <i class="fas fa-question-circle"></i>
      </a>
    </div>

    <div
      id="details-disclaimer-modal"
      class="modal fade p-0 disclaimerModal"
      tabindex="-1"
      aria-labelledby="show-details-disclaimer"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Details Disclaimer
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              All vehicles are one of each. All Pre-Owned or Certified vehicles are Used. Tax, Title, and Tags are not included in vehicle prices shown and must be paid by the purchaser. All offers expire on close of business the day subject content is removed from website, and all financing is subject to credit approval. All transactions are negotiable including price, trade allowance, interest rate (of which the dealer may retain a portion), term, and documentary service fee. $85 dealer documentation charge is non-negotiable. Any agreement is subject to execution of contract documents. While great effort is made to ensure the accuracy of the information on this site, errors do occur so please verify information with a customer service rep. This is easily done by calling us at 559-435-5000 or by visiting us at the dealership.
            </p>
            <p>
              New and used prices do not include government fees, and taxes, any finance charges, any dealer document processing charge, any electronic filing charge, and any emission testing charge. Notice, all of our vehicles have an anti-theft system installed. The advertised price excludes the purchase of this anti-theft system.
            </p>
            <p>
              **With approved credit. Terms may vary. Monthly payments are only estimates derived from the vehicle price with a 72-month term, 4.9% interest, and 20% down payment.
            </p>
            <p>
              Some of our used vehicles may be subject to unrepaired safety recalls. Check for a vehicle’s unrepaired recalls by VIN at <a href="https://vinrcl.safecar.gov/vin/">Check for Recalls Here</a>
            </p>
            <p>
              *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your vehicle, driving conditions, and other factors.
            </p>
            <p>
              *MPG for hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your vehicle, driving conditions, battery-pack age/condition (hybrid trims), and other factors.
            </p>
            <p>
              While every effort has been made to ensure display of accurate data, the vehicle listings within this web site may not reflect all accurate vehicle items including photos, information appearing herein are presented and declared to the user on as “as-is” basis without any warranties of any kind, either expressed or implied, including but not limited to the implied warranties of merchantability. All inventory listed is subject to prior sale. The vehicle photo displayed may be an example only. Please see the dealer for details. Some vehicles shown with optional equipment. See the actual vehicle for complete accuracy of features, options & pricing. Because of the numerous possible combinations of vehicle models, styles, colors and options, the vehicle pictures on this site may not match your vehicle exactly; however, it will match as closely as possible. Some vehicle images shown are stock photos and may not reflect your exact choice of vehicle, color, trim and specification. Not responsible for pricing or typographical errors. Please verify individual inventory price(s) with the dealer.
            </p>
            <p>
              All information pertaining to any vehicle should be verified through the dealer.
            </p>
            <p>
              *Notice regarding Security System: All of our vehicles are equipped with an anti-theft device to prevent theft from our inventory. The advertised price for all of our vehicles does not include the price of the anti-theft device. This device can be purchased for an additional cost, or may be removed at the customer’s option.
            </p>
            <p>
              Any image of a stock photo may not reflect the model year or color of, and/or equipment offered on, the advertised vehicle. The image is shown for illustration purposes only.
            </p>
            <p>
              Proposition 65 Warning: Operating, servicing, and maintaining a passenger vehicle or off-road vehicle can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the State of California to cause cancer and birth defects or other reproductive harm. To minimize exposure, avoid breathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated area, and wear gloves or wash your hands frequently when servicing your vehicle. For more information go to <a href="https://www.p65warnings.ca.gov/products/passenger-vehicle/">p65Warnings</a>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>

    <div @click="car.banner ? null : goToVDP()">
      <div class="search-results-card-header padding-small" v-if="!car.banner">
        <div class="search-results-card-header-name">
          <span v-if="car.new" class="search-results-card-header-name-new">
						New
					</span>
          <span class="search-results-card-header-name-bold">
						{{ car.year }} {{ car.make }} {{ car.model }}
					</span>
          <span class="search-results-card-header-trim">
						{{ car.style }}
					</span>
        </div>
        <div class="search-results-card-header-bottom">
					<div class="search-results-card-header-bottom-left">
          	<div class="search-results-card-header-bottom-left-stock">
          	  <span class="search-results-card-header-bottom-left-stock-bold">
								STOCK #:
							</span>
          	  <span class="search-results-card-header-bottom-left-stock-number">
								{{ car.stock_number }}
							</span>
          	</div>
          	<div class="search-results-card-header-bottom-left-vin">
          	  <span class="search-results-card-header-bottom-left-vin-bold">
								VIN:
							</span>
          	  {{ car.vin }}
          	</div>
					</div>
					<div class="search-results-card-header-bottom-right">
						<div class="search-results-card-header-bottom-right-car-gurus">
							<span :data-cg-vin="car.vin" :data-cg-price="car.price"></span>
						</div>
					</div>
        </div>
      </div>
      <div class="search-results-card-top-holster" v-if="!car.banner">
	  		<div class="img-holster">
          <img
            class="search-results-card-img"
            v-bind:src="
              car.thumbnail ? car.thumbnail : '/static/images/comingSoon.webp'
            "
          />
        </div>
        <div class="search-results-card-price">
          <div
            class="search-results-card-badges"
            v-if="car.certification == 'Certified Pre-Owned'"
          >
            <img
              class="cpoBadge"
              src="/static/images/cpoBadge.webp"
              alt="CPO Badge"
            />
          </div>
          <div
            class="search-results-card-badges-lg"
            v-if="car.certification == 'Clawson Customs'"
          >
            <img
              class="cpoBadge"
              src="/static/images/Clawson-CC-FNL.webp"
              alt="CC Badge"
            />
          </div>
          <div
            class="search-results-card-badges"
            v-if="car.certification == 'Clawson Certified Pre-Owned'"
          >
            <img
              class="cpoBadge"
              src="/static/images/clawson-certified-truck.webp"
              alt="CCPO Badge"
            />
          </div>
          <div class="search-results-card-price-number">
            <span
              class="search-results-card-price-number-price-ridge"
              v-if="
                car.other_price
                && parseInt( car.other_price, 10 ) - parseInt( car.price, 10 ) > 0
              "
            >
              <div class="top">
                <span class="left pb-2"><span class="msrp">WAS</span> ${{ car.other_price | formatPrices }}</span>
                <span class="right"><span class="badge">- </span>${{ parseInt( car.other_price, 10 ) - parseInt( car.price, 10  ) | formatPrices }}<span class="msrp noBadge">OFF</span></span>
              </div>
              <div class="bottom">
                <span class="salePrice"><span class="msrp">NOW</span> $<span class="msrp-dollar-number">{{ car.price | formatPrices }}</span></span>
              </div>
            </span>
            <div v-else-if="car.price">
              <span class="search-results-card-price-number-price">
                ${{ car.price | formatPrices }}
              </span>
              <span class="search-results-card-price-msrp">Price</span>
            </div>
            <span class="search-results-card-price-number" v-else>
              Call For More Details
            </span>
          </div>
        </div>
				<div
				  class="flickFusionButton srp m-2"
				  id="ff_link"
				  ff_client="8EDB5FF6-28AB-0AD6-2C2F-F7639EDD3F68"
				  v-bind:ff_vin="car.vin"
				  v-bind:class="{ hover: ffBtnHover }"
				  @mouseover="ffBtnHover = true"
				  @mouseleave="ffBtnHover = false"
				></div>
      </div>
      <div class="search-results-card-details" v-if="!car.banner">
        <div class="details--left">
          <p class="search-results-card-details-int-color">
            <span
              class="colorDot"
              v-bind:style="{ 'background-color': transformColor(car.interior_color) }">
            </span>
            Interior: {{ car.interior_color }}
          </p> 
          <p class="search-results-card-details-ext-color">
            <span
              class="colorDot"
              v-bind:style="{ 'background-color': transformColor(car.colour) }"
            >
            </span>
            Exterior: {{ car.colour }}
          </p>
          <p 
            class="search-results-card-details-material"
            v-if="car.interior_material"
          >
            Interior: {{ car.interior_material }}
          </p>
        </div>
        <div class="details--right">
          <p class="search-results-card-details-engine">
            <!-- make sure 2.0T is showing on honda accords -->
            <span v-if="car.style && car.style.includes('2.0T')">2.0T</span>
            {{ car.engine }}
          </p>
          <p class="search-results-card-details-trans">
            {{ car.transmission }} Transmission
          </p>
          <p class="search-results-card-details-drive-train">
            {{ car.drivetrain_description }}
          </p>
          <p v-if="car.milage" class="search-results-card-details-mileage">
            {{ car.mileage.toString(10).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) }} mi.
          </p>
          <p v-else-if="car.odometer" class="search-results-card-details-mileage">
            {{ car.odometer.toString(10).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) }} mi.
          </p>
        </div>
      </div>
      <div class="search-results-card-bottom-holster" v-if="!car.banner">
        <div class="search-results-card-mpg">
          <div class="search-results-card-mpg-hwy">
            <span class="search-results-card-mpg-hwy-num number">
              HWY {{ car.highway_mpg }} mpg {{ showFuelAsterisks(car.fuel) }}
            </span>
          </div>
          <div class="search-results-card-mpg-symbol">
            <svg
              width="28"
              height="34"
              viewBox="0 0 28 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 17.5V29.5C3 31.1569 4.16406 32.5 5.6 32.5H13.4C14.8359 32.5 16 31.1569 16 29.5V17.5"
                stroke="#0072ce"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <rect
                x="1"
                y="4.5"
                width="17"
                height="13"
                rx="2"
                stroke="#0072ce"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M3 4C3 2.34315 4.26406 1 5.7 1L13.5 1C14.9359 1 16 2.34315 16 4"
                stroke="#0072ce"
                stroke-opacity="0.5"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M12 13.3457C11.9117 13.4415 11.7534 13.5426 11.5249 13.6489C11.3017 13.75 11.0524 13.8351 10.7773 13.9043C10.5073 13.9681 10.2606 14 10.0374 14C9.42991 14 8.89512 13.8803 8.43302 13.641C7.97612 13.3963 7.62305 13.0612 7.37383 12.6356C7.12461 12.2048 7 11.7154 7 11.1676C7 10.4814 7.1324 9.9016 7.3972 9.42819C7.66199 8.94947 8.01765 8.59309 8.46417 8.35904C8.9107 8.11968 9.40395 8 9.94393 8C10.2918 8 10.6189 8.03989 10.9252 8.11968C11.2316 8.19947 11.5016 8.30319 11.7352 8.43085L11.3692 9.50798C11.2186 9.42287 11.0109 9.34574 10.7461 9.2766C10.4865 9.20213 10.2684 9.16489 10.0919 9.16489C9.46885 9.16489 8.99117 9.32447 8.65888 9.64362C8.33178 9.96277 8.16822 10.4362 8.16822 11.0638C8.16822 11.4255 8.2487 11.742 8.40966 12.0133C8.57061 12.2793 8.79387 12.484 9.07944 12.6277C9.3702 12.7713 9.6999 12.8431 10.0685 12.8431C10.4216 12.8431 10.6838 12.7926 10.8551 12.6915V11.9814H9.86604V10.8644H12V13.3457Z"
                fill="#0072ce"
              />
              <path
                d="M18 23.5C20.7614 23.5 23 21.227 23 18.4231V18M23 12C23 9 23.5 7.5 27 6M23 12C26.25 10 26.25 13.25 26.25 13.25L24.5 14.5V16.75L23 18M23 12V15.5V18"
                stroke="#0072ce"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="search-results-card-mpg-city">
            <span class="search-results-card-mpg-city-num number"
              >CITY {{ car.city_mpg }} mpg
              {{ showFuelAsterisks(car.fuel) }}</span
            >
          </div>
        </div>
      </div>
      <a :href="car.link" v-if="car.banner">
        <img :src="car.url" />
      </a>
    </div>
    <div class="d-flex align-content-center justify-content-center mb-4">
      <b-button
        href="#"
        class="btn btn-tertiary btn-icon"
        style="
          background: linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7 !important;
          box-shadow: 0px 4px 24px !important;
          border-radius: 5px !important;
          font-weight: 600 !important;
        "
      >
        <i class="far fa-check-circle"></i>
        <span>Confirm Availability</span>
      </b-button>
    </div>
    <!--     
    <div
      class="iPacketSection d-flex align-content-center justify-content-center mb-4"
      style="min-height: 78px"
    >
      <div v-if="car.new" class="newIPacketbutton">
        <a :href="`https://www.ipacket.info/${car.vin}`" target="_blank">
          <img :src="`https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2AE&vin=${car.vin}`" />
        </a>
      </div>
      <div v-else class="usedIPacketbutton">
        <a :href="`https://www.ipacket.info/${car.vin}`" target="_blank">
          <img :src="`https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2A9&vin=${car.vin}`" />
        </a>
      </div>
    </div>
    <div class="fuse-button-card">
      <a
        class="fuse-cta-button"
        :href="`https://clawsonhonda.fuseautotech.com/?identifier=${car.vin}&dealership=hondafresno&url=${location}&groupName=clawson`"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" id="money-check-dollar">
          <path
            d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164.1 160v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7 .1 5c.3 .5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8 .2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6V352c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1l0 0 0 0c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7 .7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z"
            fill="currentColor"
          />
        </svg>
        Customize My Payment
      </a>
    </div>
    -->
    <!-- * Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <span
      class="drivemotors"
      data-ext-dealer-code="2264"
      :data-vin="car.vin"
      :data-msrp="car.msrp"
      :data-price="car.price"
      :data-stock-code="car.stock_number"
      :data-model-num="car.model"
      :data-model-trim="car.trim"
      :data-ext-color="car.colour"
      :data-int-color="car.interior_color"
      :data-is-new="car.new === 'true' ? 1 : 0"
      :data-miles="car.odometer ? car.odometer : 0"
    ></span>
  </div>
</template>

<script>
import { cssColorNames } from "../helpers";

export default {
  data: function() {
    return {
      detailsHover: false,
      cardHover: false,
      ffBtnHover: false,
      location: '',
    };
  },
  props: {
    car: null,
    carid: null,
    viewDetailsPage: null,
    withDetails: {
      default: true,
    },
  },
  computed: {
    cardClasses: function() {
      let classObj = {};
      let hoverClass = this.cardHover && !this.detailsHover && !this.ffBtnHover;
      let bannerClass = this.car.banner;

      classObj.hover = hoverClass;
      classObj.banner = bannerClass;

      return classObj;
    },
  },
  beforeMount() {
    if (!this.car.banner && this.car["photos"] != null) {
      this.car["photos"] = this.car["photos"].split("|");
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  mounted() {
    var CarGurus = window.CarGurus || {};
    window.CarGurus = CarGurus;
    CarGurus.DealRatingBadge = window.CarGurus.DealRatingBadge || {};
    CarGurus.DealRatingBadge.options = {
      style: "STYLE2",
      minRating: "GOOD_PRICE",
      defaultHeight: "60",
    };

		( function() {
			var script   = document.createElement( 'script' );
			script.src   ="https://static.cargurus.com/js/api/en_US/1.0/dealratingbadge.js";
			script.async = true;
			var entry    = document.getElementsByTagName( 'script' )[0];
			entry.parentNode.insertBefore( script, entry );
		} )();
	},
  beforeUpdate() {
    if (!this.car.banner && this.car["photos"] != null) {
      if (typeof this.car["photos"] != "object") {
        this.car["photos"] = this.car["photos"].split("|");
      }
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  methods: {
    transformColor(colorText) {
      // Check if color property exists & return default black if not
      if (colorText === null) {
        return 'black';
      }
      let strippedColor = colorText.replace(/\s+/g, '').toLowerCase();
      let colorMatch = '';

      for (const color in cssColorNames) {
        if (strippedColor.includes(color)) {
          // Always pick the more descriptive match
          // example: blue < violetblue
          if (colorMatch.length < color.length) {
            colorMatch = cssColorNames[color];
          }
        }
      }
      // Default to black if no match found
      if (colorMatch) {
        return colorMatch;
      } else {
        return 'black';
      }
    },
    viewDetailsPageChild: function (id) {
      console.log("PARENT: ", this.$refs);
      console.log("Clicking the view details page Child");
      if ("viewDetailsPageChild" in this.$parent) {
        this.$parent.viewDetailsPageChild(id);
      } else if ("viewDetailsPage" in this.$parent) {
        this.$parent.viewDetailsPage(id);
      } else {
        this.$emit("viewDetails", id);
      }
    },

    goToVDP() {
      if (this.car.certification == "Clawson Certified Pre-Owned" || this.car.certification == "Certified Pre-Owned") {
        window.location.href = "/search/certified/" + this.car.vin + "/";
      } else {
        if (!this.car.new) {
          window.location.href = "/search/used/" + this.car.vin + "/";
        } else {
          window.location.href = "/search/new/" + this.car.vin + "/";
        }
      }
    },
    showFuelAsterisks: function (fuelType) {
      return fuelType == "Hybrid" ? "**" : "*";
    },
  },

  beforeMount() {
    if (!this.car.banner && this.car["photos"] != null) {
      this.car["photos"] = this.car["photos"].split("|");
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  beforeUpdate() {
    if (!this.car.banner && this.car["photos"] != null) {
      if (typeof this.car["photos"] != "object") {
        this.car["photos"] = this.car["photos"].split("|");
      }
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  filters: {
    formatPrices: function(number) {
      if (number != null) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
};
</script>
