var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vdp-related-cars-carousel carousel-container carousel" },
    [
      _c(
        "swiper-container",
        {
          staticClass: "related-carousel",
          attrs: {
            "slides-per-view":
              _vm.related_cars.length < 3
                ? _vm.related_cars.length
                : _vm.slidesPerView,
            "space-between": 16,
            "centered-slides":
              _vm.related_cars.length < 3
                ? _vm.related_cars.length % 2 !== 0
                : _vm.slidesPerView % 2 !== 0,
            loop: _vm.related_cars.length > 3,
            "initial-slide":
              _vm.related_cars.length < 3
                ? _vm.related_cars.length % 2 !== 0
                  ? 1
                  : 0
                : _vm.slidesPerView % 2 !== 0
                ? 1
                : 0,
            navigation: _vm.related_cars.length > 3
          }
        },
        _vm._l(_vm.related_cars, function(car) {
          return _c(
            "swiper-slide",
            { key: car.id, staticClass: "vehicle-card-wrapper" },
            [_c("new-vehicle-card", { attrs: { car: car } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }