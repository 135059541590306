<template>
  <b-card class="search-results-card" no-body>
    <b-card-header @click="goToVDP">
      <b-card-img
        :src="thumbnail"
        :alt="`${car.year} ${car.make} ${car.model} - image`"
        style="cursor: pointer;"
        top
      />
    </b-card-header>
    <b-card-body class="search-results-card-body">
      <div class="details-disclaimer">
        <b-button
          class="show-details-disclaimer"
          v-b-modal.details-disclaimer-modal
        >
          <b-icon icon="question-circle"></b-icon>
        </b-button>
      </div>
      <b-row>
        <b-col cols="12">
          <div class="search-results-card-header-name" @click="goToVDP">
            <p class="search-results-card-header-name-bold">
              {{ car.year }} {{ car.make }} {{ car.model }}
              <span>{{ car.style }} {{ seriesDetailEval }}</span>
            </p>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row class="search-results-card-special-info">
        <b-col cols="10" class="search-results-card-special-info--left">
          <div class="vin">
            <span class="vin-bold">
              VIN:
            </span>
            {{ car.vin }}
          </div>
          <div class="stock">
            <span class="stock-bold">
              STOCK #:
            </span>
            {{ car.stock_number }}
          </div>
        </b-col>
        <b-col cols="2" class="search-results-card-special-info--right">
          <div class="row align-items-center justify-content-around">
            <div class="linkIconWrapper">
              <b-icon v-b-tooltip.hover.topright title="Share Vehicle" @click="copyShareLink" icon="share" class="copyShareLinkIcon"></b-icon>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row class="search-results-card-price">
        <b-col cols="12">
          <!-- <div
            class="search-results-card-price-number"
            v-if="
            (
              ( car.new && ['Ridgeline'].includes( car.model) ) ||
              ( car.new && ['Accord'].includes( car.model ) && car.year == '2023' && car.style && car.style.search(/(\blx\b)|(\bex\b)/gi) != -1 )
            ) && car.msrp && car.price
                    && parseInt( car.msrp, 10 ) - parseInt( car.price, 10 ) > 0
            "
          > -->
					<!-- <div
            class="search-results-card-price-number"
            v-if="car.new && car.msrp && car.price && parseInt( car.msrp, 10 ) - parseInt( car.price, 10 ) > 0"
          >
            <span class="search-results-card-price-number-price-ridge">
              <p class="top">
                <span class="msrp">MSRP</span> ${{ car.msrp | formatPrices }}
                <span class="badge">- </span>${{ parseInt( car.msrp, 10 ) - parseInt( car.price, 10 ) | formatPrices }}
                <span class="msrp"> OFF</span>
              </p>
              <p class="salePrice bottom">
                <span class="msrp">NOW</span> $
                <span class="msrp-dollar-number">{{ car.price | formatPrices }}</span>
              </p>
            </span>
          </div>
          <div
            class="search-results-card-price-number"
            v-else-if="car.new && car.msrp && car.price && parseInt( car.msrp, 10 ) - parseInt( car.price, 10 ) < 0"
          >
            <span class="search-results-card-price-number">
              Call For Price
            </span>
          </div> -->

          <div class="search-results-card-price-number">
            <span
              class="search-results-card-price-number-price-ridge"
              v-if="
                car.other_price
                && parseInt( car.other_price, 10 ) - parseInt( car.price, 10 ) > 0
              "
            >
              <div class="top">
                <span class="left pb-2"><span class="msrp">WAS</span> ${{ car.other_price | formatPrices }}</span>
                <span class="right"><span class="badge">- </span>${{ parseInt( car.other_price, 10 ) - parseInt( car.price, 10  ) | formatPrices }}<span class="msrp noBadge">OFF</span></span>
              </div>
              <div class="bottom">
                <span class="salePrice"><span class="msrp">NOW</span><br />$<span class="msrp-dollar-number">{{ car.price | formatPrices }}</span></span>
              </div>
            </span>
            <div v-else-if="car.price">
              <span class="search-results-card-price-number-price">
                ${{ car.price | formatPrices }}
              </span>
            </div>
            <span class="search-results-card-price-number" v-else>
              Call For More Details
            </span>
          </div>
        </b-col>
				<b-col cols="12">
        	<div
        	  class="flickFusionButton srp m-2"
        	  id="ff_link"
        	  ff_client="8EDB5FF6-28AB-0AD6-2C2F-F7639EDD3F68"
        	  v-bind:ff_vin="car.vin"
        	  v-bind:class="{ hover: ffBtnHover }"
        	  @mouseover="ffBtnHover = true"
        	  @mouseleave="ffBtnHover = false"
        	></div>
				</b-col>
      </b-row>
      <hr />
      <b-row class="search-results-card-details">
        <b-row>
          <b-col cols="6">
            <div class="search-results-card-details-int-color">
              <span
                class="colorDot"
                :style="{ 'background-color': transformColor(car.interior_color) }"
              ></span>
              Interior: {{ car.interior_color }}
            </div>
            <div class="search-results-card-details-ext-color">
              <span
                class="colorDot"
                :style="{ 'background-color': transformColor(car.colour) }"
              ></span>
              Exterior: {{ car.colour }}
            </div>
            <br />
            <div class="search-results-card-details-mileage">
              <b-icon icon="speedometer2" class="search-results-card-details-mileage-icon"></b-icon>
              {{ car.highway_mpg ? car.highway_mpg.toString(10).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : '--' }} MPG HWY {{ asterisk }}
            </div>
            <div class="search-results-card-details-mileage">
              <b-icon icon="speedometer2" class="search-results-card-details-mileage-icon"></b-icon>
              {{ car.city_mpg ? car.city_mpg.toString(10).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : '--' }} MPG CITY {{ asterisk }}
            </div>
            <div class="search-results-card-details-mileage">
              <b-icon icon="speedometer" class="search-results-card-details-mileage-icon"></b-icon>
              <span style="font-weight: 700;">{{ car.mileage ? car.mileage.toString(10).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : '--' }} Miles</span>
            </div>
          </b-col>
          <b-col cols="6">
            <p v-if="car.engine" class="search-results-card-details-engine">
              <!-- make sure 2.0T is showing on honda accords -->
              <span v-if="car.style && car.style.includes('2.0T')">2.0T</span>
              {{ car.engine }}
            </p>
            <p v-if="car.transmission" class="search-results-card-details-trans">
              {{ car.transmission }} Transmission
            </p>
            <p v-if="car.drivetrain_description" class="search-results-card-details-drive-train">
              {{ car.drivetrain_description }}
            </p>
          </b-col>
        </b-row>
        <b-row class="justify-content-start align-items-end badge-sash">
          <b-col v-if="car.certification" cols="6" :class="[car.certification ? cPOBadgeMap[car.certification].class : '', 'badge']">
            <img
              loading="lazy"
              class="cpoBadge"
              :src="cPOBadgeMap[car.certification].src"
              :alt="cPOBadgeMap[car.certification].alt"
            />
          </b-col>
          <b-col cols="6" class="dealRatingBadge">
            <span :data-cg-vin="car.vin" :data-cg-price="car.price"></span>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <div class="linkIconWrapper">
            <b-button pill style="margin:auto;">
              <b-icon icon="check-circle" class="checkLinkIcon"></b-icon>
              Confirm Availability
            </b-button>
          </div>
        </b-row>
      </b-row>
      <b-row class="align-content-center justify-content-center mt-3">
        <b-col cols="12">
          <p class="disclaimerText">
            Stock images are for illustration purposes only
          </p>
        </b-col>
      </b-row>
			<b-row class="align-content-center justify-content-center mt-3">
				<!-- Added CarNow CTA 11/16/23 as recieved by Erica -->
				<div class="cn-button-container" :data-vin="car.vin" data-page-type="srp" data-type="used"></div>
    		<span
    		  class="drivemotors"
    		  data-ext-dealer-code="2264"
    		  :data-vin="car.vin"
    		  :data-msrp="car.msrp"
    		  :data-price="car.price"
    		  :data-stock-code="car.stock_number"
    		  :data-model-num="car.model"
    		  :data-model-trim="car.trim"
    		  :data-ext-color="car.colour"
    		  :data-int-color="car.interior_color"
    		  :data-is-new="0"
    		  :data-miles="car.odometer ? car.odometer : 0"
    		></span>
			</b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { cssColorNames } from "../helpers";

export default {
  data : () => ( {
    cPOBadgeMap : {
      'Certified Pre-Owned'         : { src : '/static/images/cpoBadge.webp', alt : 'CPO Badge', class : 'search-results-card-badges' },
      'Clawson Customs'             : { src : '/static/images/Clawson-CC-FNL.webp', alt : 'Clawson Customs Badge', class : 'search-results-card-badges-lg' },
      'Clawson Certified Pre-Owned' : { src : '/static/images/Clawson-Certified-HONDA-LIGHT.webp', alt : 'Clawson Certified Pre-Owned Badge', class : 'search-results-card-badges' },
      'HondaTrue Used'              : { src : '/static/images/hondaTrue/HondaTrue_Used.webp', alt : 'HondaTrue Used Badge', class : 'search-results-card-badges-true' },
      'HondaTrue Certified'         : { src : '/static/images/hondaTrue/HondaTrue_Certified.webp', alt : 'HondaTrue Certified Badge', class : 'search-results-card-badges-true' },
      'HondaTrue Certified+'        : { src : '/static/images/hondaTrue/HondaTrue_Certified_Plus.webp', alt : 'HondaTrue Certified+ Badge', class : 'search-results-card-badges-true' },
    },
		host         : window.location.host,
  } ),
  props: {
    car : {
      type    : Object,
      default : null,
    },
  },
  computed: {
    seriesDetailEval: function() {
      const seriesDetail = this.car.seriesDetail ? this.car.seriesDetail : "";
      if (
        !seriesDetail ||
        seriesDetail.length === 0 ||
        seriesDetail === 'null' ||
        seriesDetail === '' ||
        seriesDetail.toLowerCase().includes( 'passenger' )
      ) return '';
      return seriesDetail;
    },
    asterisk: function() {
      if (
        typeof this.car.fuel === 'string' &&
        (
          this.car.fuel.toLowerCase().includes( 'electric' ) ||
          this.car.fuel.toLowerCase().includes( 'hybrid' )
        )
      ) return '**';
      return '*';
    },
    shareLink : function() {
      if ( ["Certified Pre-Owned", "Clawson Certified Pre-Owned"].includes( this.car.certification ) ) {
        return "/search/certified/" + this.car.vin + "/";
      } else {
        return "/search/new/" + this.car.vin + "/";
      }
    },
    thumbnail : function() {
      if (this.car.thumbnail) return this.car.thumbnail;
      return ['U', 'T'].includes( this.car.stock_number.split('')[0] ) ? '/static/images/tCComingSoon.webp' : '/static/images/hComingSoon.webp';
    } 
  },
  mounted() {
    const CarGurus = window.CarGurus || {};
    window.CarGurus = CarGurus;
    CarGurus.DealRatingBadge = window.CarGurus.DealRatingBadge || {};
    CarGurus.DealRatingBadge.options = {
      style         : "STYLE2",
      minRating     : "GOOD_PRICE",
      defaultHeight : "60",
    };

    ( function() {
      const script = document.createElement( "script" );
      script.src = "https://static.cargurus.com/js/api/en_US/1.0/dealratingbadge.js";
      script.async = true;
      const entry = document.getElementsByTagName( "script" )[0];
      entry.parentNode.insertBefore( script, entry );
    } )();
  },
  methods: {
    transformColor( colorInput ) {
      if ( typeof colorInput !== 'string' ) return '#000000';
			const strippedColor = colorInput.replace( /\s+/g, '' ).toLowerCase();
			const color = cssColorNames[strippedColor] ? cssColorNames[strippedColor] : "#000000";
			return color;
		},
    viewDetailsPageChild(id) {
      if ( "viewDetailsPageChild" in this.$parent ) {
        this.$parent.viewDetailsPageChild( id );
      } else if ( "viewDetailsPage" in this.$parent ) {
        this.$parent.viewDetailsPage( id );
      } else {
        this.$emit( "viewDetails", id );
      }
    },

    copyShareLink() {
      if ( navigator.share ) {
        navigator.share( {
          title : 'Clawson Truck Center',
          text  : 'Check out this vehicle!',
          url   : `https://${this.host}${this.shareLink}`,
        } ).catch( console.error );
        return;
      }
      navigator.clipboard.writeText( `https://${this.host}${this.shareLink}` );
      this.makeToast();
    },
    makeToast() {
      this.$bvToast.toast( 'Copy Link success', {
        title   : 'Success',
        variant : 'success',
        solid   : true,
        toaster : navigator.orientation > 1 ? 'b-toaster-top-center' : 'b-toaster-top-right',
      });
    },

    goToVDP() {
      window.location.href = this.shareLink;
    },
  },

  beforeMount() {
    if ( !this.car.banner && this.car["photos"] != null ) {
      this.car["photos"] = this.car["photos"].split( "|" );
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  beforeUpdate() {
    if ( !this.car.banner && this.car["photos"] != null ) {
      if ( typeof this.car["photos"] != "object" ) {
        this.car["photos"] = this.car["photos"].split( "|" );
      }
      this.car["thumbnail"] = this.car["photos"][0];
    }
  },
  filters: {
    formatPrices: function( number ) {
      if ( number != null ) {
        return number.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
      }
    },
  },
};
</script>
