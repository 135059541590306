require('./clawson-vue');
require('./utils');

$(document).ready(function() {
  var windowsize = $(window).width();

  if (windowsize <= 992) {
    $('#menu-btn').prop('checked');

    $('#menu-btn').change(function() {
      if ($(this).prop("checked") == true) {
        $('.clawson-header').addClass('menuOpened');
      }
      else if ($(this).prop("checked") == false) {
        $('.clawson-header').removeClass('menuOpened');
      }
    });
  }

  // temp fix for safari/iOS carchat issue
  $(document).on("click", (e) => {
    // check if click in bottom left corner
    if (e.originalEvent) {
      let bub = $('[class*="round-chat"');
      let isBot = e.originalEvent.y >= bub.offset().top;
      let isTop = e.originalEvent.x <= 90;
      // force a click on the carchat icon
      if (isBot && isTop) {
        bub.click();
      }
    }
  });

});



$(window).scroll(function() {
  if ($(window).scrollTop() > 0) {
    $(".clawson-header").addClass("scrolling");
  } else if ($(window).scrollTop() == 0) {
    $(".clawson-header").removeClass("scrolling");
  }
});

$(function () {
   const addMaximumScaleToMetaViewport = () => {
       const el = document.querySelector('meta[name=viewport]');

       if (el !== null) {
           let content = el.getAttribute('content');
           let re = /maximum\-scale=[0-9\.]+/g;

           if (re.test(content)) {
	 content = content.replace(re, 'maximum-scale=1.0');
           } else {
	 content = [content, 'maximum-scale=1, user-scalable=0'].join(', ')
           }

           el.setAttribute('content', content);
       }
   };

   const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

   // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
   const checkIsIOS = () =>
       /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

   if (checkIsIOS()) {
       disableIosTextFieldZoom();
   }
});
