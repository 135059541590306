<template>
  <ais-instant-search
    :index-name="indexName"
    :search-client="searchClient"
    :search-function="searchFunction"
    class="col-4 d-flex flex-auto justify-content-end"
  >
    <div
      :class="[
        smallSearchIsOpened ? 'opened' : 'closed',
        'menu-menu__item header-search-bar d-flex',
      ]"
    >
      <!-- search bar -->
      <ais-autocomplete ref="searchAuto">
        <template v-slot:default="{ currentRefinement, indices, refine }">
          <b-input-group>
            <b-form-input
              type="search"
              ref="suggestInput"
              :value="currentRefinement"
              placeholder="Search for a vehicle..."
              @input="refine"
              autocomplete="off"
              @focusin="suggestState()"
              @focusout="handleFocusOut()"
              @keyup.enter="searchState(currentRefinement)"
            ></b-form-input>

            <template v-slot:append>
              <b-icon
                class="header-search-enter"
                icon="search"
                font-scale="2"
                @click="searchState(currentRefinement)"
                style="margin-left : 4px;align-self: flex-end;justify-self: center;"
              ></b-icon>
            </template>
          </b-input-group>

          <div v-if="currentRefinement && suggestOpenable" class="autopanel">
            <div class="hits">
              <ul
                v-for="index in indices"
                :key="index.label"
                class="list-group"
              >
                <h4>Results</h4>
                <li v-show="index.hits.length == 0" class="list-group-item">
                  <em>No matches...</em>
                </li>
                <li
                  v-for="hit in index.hits"
                  :key="hit.objectID"
                  class="list-group-item"
                >
                  <a
                    v-if="!hit.new && ( hit.body === 'Truck' || hit.body === 'SUV' )"
                    :href="hitLink(hit)"
                    class="stretched-link"
                  >
                    <div class="suggestion-content">
                      <div class="suggestion-thumbnail">
                        <img :src="hit.photos ? hit.photos.split('|')[0] : ['U', 'T'].includes(hit.stock_number) ? '/static/images/tCComingSoon.webp' : '/static/images/hComingSoon.webp'" alt="clawson honda" width="100" loading="lazy" />
                      </div>
                      <div class="suggestion-details">
                        <strong>
                          <span v-if="hit.new">New</span>
                          <span
                            >{{ hit.year }} {{ hit.make }} {{ hit.model }}</span
                          >

                          <span v-if="hit.style">{{ hit.style }}</span>
                          <span v-if="hit.door_count"
                            >{{ hit.door_count }}D</span
                          >
                          <span v-if="hit.body">{{ hit.body }}</span>
                        </strong>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </ais-autocomplete>
    </div>
    <b-icon
      icon="search"
      class="header-search-sm"
      @click="toggleSmallSearch()"
    ></b-icon>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { ALGOLIA_APP_ID, ALGOLIA_INDEX, ALGOLIA_API_KEY } from "config";

export default {
  data: () => ( {
    searchClient        : algoliasearch( ALGOLIA_APP_ID, ALGOLIA_API_KEY ),
    indexName           : ALGOLIA_INDEX,
    suggestOpenable     : true,
    smallSearchIsOpened : false,
  } ),

  mounted() {
    $(".dropdown-menu").click(function (e) {
      e.stopPropagation();
    });
  },

  methods: {
    // closes suggest box for user to browse results
    // but not lose results.
    toggleSmallSearch() {
      this.smallSearchIsOpened = !this.smallSearchIsOpened;

      if (this.smallSearchIsOpened) {
        this.$refs.suggestInput.focus();
      }
    },

    searchFunction(helper) {
      // makes sure crv/hrv values are properly resolved in input.
      switch (helper.state.query) {
        case "crv":
          helper.setQuery("cr-v").search();
          break;
        case "hrv":
          helper.setQuery("hr-v").search();
          break;
        default:
          helper.search(helper.state.query);
      }
    },

    hitLink(hit) {
      // if (hit.new) {
      //   return "/search/new/" + hit.vin + "/";
      // }

      if (
        !hit.new &&
        (hit.certification == "Clawson Certified Pre-Owned" ||
          hit.certification == "Certified Pre-Owned")
      ) {
        return "/search/certified/" + hit.vin + "/";
      } else {
        return "/search/used/" + hit.vin + "/";
      }
    },

    searchState(query) {
      this.searchClient.initIndex(this.indexName)
        .search(query)
        .then(({ hits }) => {
          // result count check.
          if (hits.length == 0) {
            window.location.href = "/search/?query=" + query;
          } else {
            window.location.href = "/search/?query=" + query;
          }
        })
        .catch((error) => {
          console.error("index check error: ", error);
        });
    },

    suggestState() {
      this.suggestOpenable = true;
    },

    handleFocusOut() {
      // give state time to think whether or not
      // user clicked on item or if focused out of search.
      setTimeout(() => {
        this.suggestOpenable = false;
      }, 400);
    },
  },
};
</script>
