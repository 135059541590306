var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-results-card",
      class: _vm.cardClasses,
      on: {
        mouseover: function($event) {
          _vm.cardHover = true
        },
        mouseleave: function($event) {
          _vm.cardHover = false
        }
      }
    },
    [
      _vm.withDetails
        ? _c(
            "div",
            {
              staticClass: "details-disclaimer",
              class: { hover: _vm.detailsHover },
              on: {
                mouseover: function($event) {
                  _vm.detailsHover = true
                },
                mouseleave: function($event) {
                  _vm.detailsHover = false
                }
              }
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              _vm.car.banner ? null : _vm.goToVDP()
            }
          }
        },
        [
          !_vm.car.banner
            ? _c(
                "div",
                { staticClass: "search-results-card-header padding-small" },
                [
                  _c(
                    "div",
                    { staticClass: "search-results-card-header-name" },
                    [
                      _vm.car.new
                        ? _c(
                            "span",
                            {
                              staticClass: "search-results-card-header-name-new"
                            },
                            [_vm._v("\n\t\t\t\t\t\tNew\n\t\t\t\t\t")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "search-results-card-header-name-bold" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.car.year) +
                              " " +
                              _vm._s(_vm.car.make) +
                              " " +
                              _vm._s(_vm.car.model) +
                              "\n\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "search-results-card-header-trim" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.car.style) +
                              "\n\t\t\t\t\t"
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-results-card-header-bottom" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "search-results-card-header-bottom-left"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "search-results-card-header-bottom-left-stock"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "search-results-card-header-bottom-left-stock-bold"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tSTOCK #:\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "search-results-card-header-bottom-left-stock-number"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.car.stock_number) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "search-results-card-header-bottom-left-vin"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "search-results-card-header-bottom-left-vin-bold"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tVIN:\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n          \t  " +
                                  _vm._s(_vm.car.vin) +
                                  "\n          \t"
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "search-results-card-header-bottom-right"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "search-results-card-header-bottom-right-car-gurus"
                            },
                            [
                              _c("span", {
                                attrs: {
                                  "data-cg-vin": _vm.car.vin,
                                  "data-cg-price": _vm.car.price
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.car.banner
            ? _c("div", { staticClass: "search-results-card-top-holster" }, [
                _c("div", { staticClass: "img-holster" }, [
                  _c("img", {
                    staticClass: "search-results-card-img",
                    attrs: {
                      src: _vm.car.thumbnail
                        ? _vm.car.thumbnail
                        : "/static/images/comingSoon.webp"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "search-results-card-price" }, [
                  _vm.car.certification == "Certified Pre-Owned"
                    ? _c("div", { staticClass: "search-results-card-badges" }, [
                        _c("img", {
                          staticClass: "cpoBadge",
                          attrs: {
                            src: "/static/images/cpoBadge.webp",
                            alt: "CPO Badge"
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.car.certification == "Clawson Customs"
                    ? _c(
                        "div",
                        { staticClass: "search-results-card-badges-lg" },
                        [
                          _c("img", {
                            staticClass: "cpoBadge",
                            attrs: {
                              src: "/static/images/Clawson-CC-FNL.webp",
                              alt: "CC Badge"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.car.certification == "Clawson Certified Pre-Owned"
                    ? _c("div", { staticClass: "search-results-card-badges" }, [
                        _c("img", {
                          staticClass: "cpoBadge",
                          attrs: {
                            src: "/static/images/clawson-certified-truck.webp",
                            alt: "CCPO Badge"
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-results-card-price-number" },
                    [
                      _vm.car.other_price &&
                      parseInt(_vm.car.other_price, 10) -
                        parseInt(_vm.car.price, 10) >
                        0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "search-results-card-price-number-price-ridge"
                            },
                            [
                              _c("div", { staticClass: "top" }, [
                                _c("span", { staticClass: "left pb-2" }, [
                                  _c("span", { staticClass: "msrp" }, [
                                    _vm._v("WAS")
                                  ]),
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm._f("formatPrices")(
                                          _vm.car.other_price
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "right" }, [
                                  _c("span", { staticClass: "badge" }, [
                                    _vm._v("- ")
                                  ]),
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("formatPrices")(
                                          parseInt(_vm.car.other_price, 10) -
                                            parseInt(_vm.car.price, 10)
                                        )
                                      )
                                  ),
                                  _c("span", { staticClass: "msrp noBadge" }, [
                                    _vm._v("OFF")
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "bottom" }, [
                                _c("span", { staticClass: "salePrice" }, [
                                  _c("span", { staticClass: "msrp" }, [
                                    _vm._v("NOW")
                                  ]),
                                  _vm._v(" $"),
                                  _c(
                                    "span",
                                    { staticClass: "msrp-dollar-number" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatPrices")(_vm.car.price)
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm.car.price
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "search-results-card-price-number-price"
                              },
                              [
                                _vm._v(
                                  "\n                $" +
                                    _vm._s(
                                      _vm._f("formatPrices")(_vm.car.price)
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "search-results-card-price-msrp" },
                              [_vm._v("Price")]
                            )
                          ])
                        : _c(
                            "span",
                            { staticClass: "search-results-card-price-number" },
                            [
                              _vm._v(
                                "\n              Call For More Details\n            "
                              )
                            ]
                          )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "flickFusionButton srp m-2",
                  class: { hover: _vm.ffBtnHover },
                  attrs: {
                    id: "ff_link",
                    ff_client: "8EDB5FF6-28AB-0AD6-2C2F-F7639EDD3F68",
                    ff_vin: _vm.car.vin
                  },
                  on: {
                    mouseover: function($event) {
                      _vm.ffBtnHover = true
                    },
                    mouseleave: function($event) {
                      _vm.ffBtnHover = false
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.car.banner
            ? _c("div", { staticClass: "search-results-card-details" }, [
                _c("div", { staticClass: "details--left" }, [
                  _c(
                    "p",
                    { staticClass: "search-results-card-details-int-color" },
                    [
                      _c("span", {
                        staticClass: "colorDot",
                        style: {
                          "background-color": _vm.transformColor(
                            _vm.car.interior_color
                          )
                        }
                      }),
                      _vm._v(
                        "\n            Interior: " +
                          _vm._s(_vm.car.interior_color) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "search-results-card-details-ext-color" },
                    [
                      _c("span", {
                        staticClass: "colorDot",
                        style: {
                          "background-color": _vm.transformColor(_vm.car.colour)
                        }
                      }),
                      _vm._v(
                        "\n            Exterior: " +
                          _vm._s(_vm.car.colour) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.car.interior_material
                    ? _c(
                        "p",
                        { staticClass: "search-results-card-details-material" },
                        [
                          _vm._v(
                            "\n            Interior: " +
                              _vm._s(_vm.car.interior_material) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details--right" }, [
                  _c(
                    "p",
                    { staticClass: "search-results-card-details-engine" },
                    [
                      _vm.car.style && _vm.car.style.includes("2.0T")
                        ? _c("span", [_vm._v("2.0T")])
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.car.engine) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "search-results-card-details-trans" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.car.transmission) +
                          " Transmission\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "search-results-card-details-drive-train" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.car.drivetrain_description) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.car.milage
                    ? _c(
                        "p",
                        { staticClass: "search-results-card-details-mileage" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.car.mileage
                                  .toString(10)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              ) +
                              " mi.\n          "
                          )
                        ]
                      )
                    : _vm.car.odometer
                    ? _c(
                        "p",
                        { staticClass: "search-results-card-details-mileage" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.car.odometer
                                  .toString(10)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              ) +
                              " mi.\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.car.banner
            ? _c("div", { staticClass: "search-results-card-bottom-holster" }, [
                _c("div", { staticClass: "search-results-card-mpg" }, [
                  _c("div", { staticClass: "search-results-card-mpg-hwy" }, [
                    _c(
                      "span",
                      { staticClass: "search-results-card-mpg-hwy-num number" },
                      [
                        _vm._v(
                          "\n              HWY " +
                            _vm._s(_vm.car.highway_mpg) +
                            " mpg " +
                            _vm._s(_vm.showFuelAsterisks(_vm.car.fuel)) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-results-card-mpg-symbol" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "28",
                          height: "34",
                          viewBox: "0 0 28 34",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M3 17.5V29.5C3 31.1569 4.16406 32.5 5.6 32.5H13.4C14.8359 32.5 16 31.1569 16 29.5V17.5",
                            stroke: "#0072ce",
                            "stroke-width": "2",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1",
                            y: "4.5",
                            width: "17",
                            height: "13",
                            rx: "2",
                            stroke: "#0072ce",
                            "stroke-width": "2",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M3 4C3 2.34315 4.26406 1 5.7 1L13.5 1C14.9359 1 16 2.34315 16 4",
                            stroke: "#0072ce",
                            "stroke-opacity": "0.5",
                            "stroke-width": "2",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M12 13.3457C11.9117 13.4415 11.7534 13.5426 11.5249 13.6489C11.3017 13.75 11.0524 13.8351 10.7773 13.9043C10.5073 13.9681 10.2606 14 10.0374 14C9.42991 14 8.89512 13.8803 8.43302 13.641C7.97612 13.3963 7.62305 13.0612 7.37383 12.6356C7.12461 12.2048 7 11.7154 7 11.1676C7 10.4814 7.1324 9.9016 7.3972 9.42819C7.66199 8.94947 8.01765 8.59309 8.46417 8.35904C8.9107 8.11968 9.40395 8 9.94393 8C10.2918 8 10.6189 8.03989 10.9252 8.11968C11.2316 8.19947 11.5016 8.30319 11.7352 8.43085L11.3692 9.50798C11.2186 9.42287 11.0109 9.34574 10.7461 9.2766C10.4865 9.20213 10.2684 9.16489 10.0919 9.16489C9.46885 9.16489 8.99117 9.32447 8.65888 9.64362C8.33178 9.96277 8.16822 10.4362 8.16822 11.0638C8.16822 11.4255 8.2487 11.742 8.40966 12.0133C8.57061 12.2793 8.79387 12.484 9.07944 12.6277C9.3702 12.7713 9.6999 12.8431 10.0685 12.8431C10.4216 12.8431 10.6838 12.7926 10.8551 12.6915V11.9814H9.86604V10.8644H12V13.3457Z",
                            fill: "#0072ce"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M18 23.5C20.7614 23.5 23 21.227 23 18.4231V18M23 12C23 9 23.5 7.5 27 6M23 12C26.25 10 26.25 13.25 26.25 13.25L24.5 14.5V16.75L23 18M23 12V15.5V18",
                            stroke: "#0072ce",
                            "stroke-width": "2",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-results-card-mpg-city" }, [
                    _c(
                      "span",
                      {
                        staticClass: "search-results-card-mpg-city-num number"
                      },
                      [
                        _vm._v(
                          "CITY " +
                            _vm._s(_vm.car.city_mpg) +
                            " mpg\n              " +
                            _vm._s(_vm.showFuelAsterisks(_vm.car.fuel))
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.car.banner
            ? _c("a", { attrs: { href: _vm.car.link } }, [
                _c("img", { attrs: { src: _vm.car.url } })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-content-center justify-content-center mb-4"
        },
        [
          _c(
            "b-button",
            {
              staticClass: "btn btn-tertiary btn-icon",
              staticStyle: {
                background:
                  "linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7 !important",
                "box-shadow": "0px 4px 24px !important",
                "border-radius": "5px !important",
                "font-weight": "600 !important"
              },
              attrs: { href: "#" }
            },
            [
              _c("i", { staticClass: "far fa-check-circle" }),
              _vm._v(" "),
              _c("span", [_vm._v("Confirm Availability")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("span", {
        staticClass: "drivemotors",
        attrs: {
          "data-ext-dealer-code": "2264",
          "data-vin": _vm.car.vin,
          "data-msrp": _vm.car.msrp,
          "data-price": _vm.car.price,
          "data-stock-code": _vm.car.stock_number,
          "data-model-num": _vm.car.model,
          "data-model-trim": _vm.car.trim,
          "data-ext-color": _vm.car.colour,
          "data-int-color": _vm.car.interior_color,
          "data-is-new": _vm.car.new === "true" ? 1 : 0,
          "data-miles": _vm.car.odometer ? _vm.car.odometer : 0
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "show-details-disclaimer",
        attrs: {
          type: "button",
          "data-target": "#details-disclaimer-modal",
          "data-toggle": "modal",
          tabindex: "0"
        }
      },
      [_c("i", { staticClass: "fas fa-question-circle" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal fade p-0 disclaimerModal",
        attrs: {
          id: "details-disclaimer-modal",
          tabindex: "-1",
          "aria-labelledby": "show-details-disclaimer",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" }
                },
                [_vm._v("\n              Details Disclaimer\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [
                _vm._v(
                  "\n              All vehicles are one of each. All Pre-Owned or Certified vehicles are Used. Tax, Title, and Tags are not included in vehicle prices shown and must be paid by the purchaser. All offers expire on close of business the day subject content is removed from website, and all financing is subject to credit approval. All transactions are negotiable including price, trade allowance, interest rate (of which the dealer may retain a portion), term, and documentary service fee. $85 dealer documentation charge is non-negotiable. Any agreement is subject to execution of contract documents. While great effort is made to ensure the accuracy of the information on this site, errors do occur so please verify information with a customer service rep. This is easily done by calling us at 559-435-5000 or by visiting us at the dealership.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              New and used prices do not include government fees, and taxes, any finance charges, any dealer document processing charge, any electronic filing charge, and any emission testing charge. Notice, all of our vehicles have an anti-theft system installed. The advertised price excludes the purchase of this anti-theft system.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              **With approved credit. Terms may vary. Monthly payments are only estimates derived from the vehicle price with a 72-month term, 4.9% interest, and 20% down payment.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              Some of our used vehicles may be subject to unrepaired safety recalls. Check for a vehicle’s unrepaired recalls by VIN at "
                ),
                _c(
                  "a",
                  { attrs: { href: "https://vinrcl.safecar.gov/vin/" } },
                  [_vm._v("Check for Recalls Here")]
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your vehicle, driving conditions, and other factors.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              *MPG for hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your vehicle, driving conditions, battery-pack age/condition (hybrid trims), and other factors.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              While every effort has been made to ensure display of accurate data, the vehicle listings within this web site may not reflect all accurate vehicle items including photos, information appearing herein are presented and declared to the user on as “as-is” basis without any warranties of any kind, either expressed or implied, including but not limited to the implied warranties of merchantability. All inventory listed is subject to prior sale. The vehicle photo displayed may be an example only. Please see the dealer for details. Some vehicles shown with optional equipment. See the actual vehicle for complete accuracy of features, options & pricing. Because of the numerous possible combinations of vehicle models, styles, colors and options, the vehicle pictures on this site may not match your vehicle exactly; however, it will match as closely as possible. Some vehicle images shown are stock photos and may not reflect your exact choice of vehicle, color, trim and specification. Not responsible for pricing or typographical errors. Please verify individual inventory price(s) with the dealer.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              All information pertaining to any vehicle should be verified through the dealer.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              *Notice regarding Security System: All of our vehicles are equipped with an anti-theft device to prevent theft from our inventory. The advertised price for all of our vehicles does not include the price of the anti-theft device. This device can be purchased for an additional cost, or may be removed at the customer’s option.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              Any image of a stock photo may not reflect the model year or color of, and/or equipment offered on, the advertised vehicle. The image is shown for illustration purposes only.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              Proposition 65 Warning: Operating, servicing, and maintaining a passenger vehicle or off-road vehicle can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the State of California to cause cancer and birth defects or other reproductive harm. To minimize exposure, avoid breathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated area, and wear gloves or wash your hands frequently when servicing your vehicle. For more information go to "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.p65warnings.ca.gov/products/passenger-vehicle/"
                    }
                  },
                  [_vm._v("p65Warnings")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v("\n              Dismiss\n            ")]
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }