var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "details-top-car-pictures-side-carousel carousel-container small"
    },
    [
      _c(
        "swiper-container",
        {
          staticClass: "details-top-car-pictures-side-carousel",
          attrs: {
            "slides-per-view": _vm.slidesPerView,
            "space-between": 0,
            "centered-slides": true,
            loop: true,
            "initial-slide": _vm.slidesPerView === 1 ? 0 : 1,
            navigation: true
          }
        },
        _vm._l(_vm.photos, function(photo, i) {
          return _c(
            "swiper-slide",
            {
              key: i,
              staticClass: "details-top-car-pictures-side-picture-container"
            },
            [
              _c("img", {
                staticClass: "details-top-car-pictures-side-picture",
                attrs: {
                  src: photo
                    ? photo
                    : ["U", "T"].includes(_vm.carId.split("")[0])
                    ? "/static/images/tCComingSoon.webp"
                    : "/static/images/hComingSoon.webp",
                  alt: ""
                }
              })
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }