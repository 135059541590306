<template>
	<b-container class="page-container vdp pt-5">
		<div id="desktopVersion" class="d-none d-md-block">
			<div class="topButtons">
				<b-row>
					<b-col cols="2">
						<b-button onClick="history.back()"
							class="btn btn-back btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3">
							<b-icon icon="chevron-left"></b-icon>
							<span> Back to Results</span>
						</b-button>
					</b-col>
					<b-col cols="5" offset="5" class="d-flex justify-content-end align-items-center">
						<b-button class="btn btn-right btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3">
							<b-icon class="mr-1" icon="check-circle"></b-icon>
							<span> Confirm Availability</span>
						</b-button>
						<b-button onclick="window.openAutoHubWidget()"
							class="btn d-flex btn-right btn-icon justify-content-around align-items-center mr-2 mr-xl-3">
							<b-icon class="mr-1" icon="bar-chart-line"></b-icon>
							<span class="header-bottom-popup-value-trade-text">
								<span class="d-none d-md-inline"> Value Your</span>
								<span>Trade</span>
							</span>
						</b-button>
						<b-button @click="copyLink"
							class="btn d-flex btn-right btn-icon justify-content-around align-items-center mr-2 mr-xl-3">
							<b-icon class="mr-1" icon="share-fill"></b-icon>
							<span> Share</span>
							<span class="d-none d-md-inline">This Car</span>
						</b-button>
						<b-modal hide-footer id="vdp-share-modal" title="Share this search">
							<div class="d-block text-center">
								<p class="my-4">
									Copy to share this link to your platform of choice!
								</p>
								<p class="my-4" style="font-size:12px;">
									<b>{{ shareLink }}</b>
								</p>
							</div>
							<b-button-group class="w-100">
								<b-button class="mt-3" variant="outline-primary" block @click="copyLink">
									<b-icon icon="files" flip-h></b-icon>
									Copy Link
								</b-button>
								<b-button class="mt-3" variant="outline-danger" block @click="$bvModal.hide('vdp-share-modal')">
									Close
								</b-button>
							</b-button-group>
						</b-modal>
					</b-col>
				</b-row>
			</div>
			<div v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdpSlider pt-4">
				<b-row>
					<b-col cols="12">
						<vehicle-images :photos="carData.sent.photos || carData.pulled.car.photos" :car-id="carData.sent.stock_number"></vehicle-images>
					</b-col>
				</b-row>
			</div>
			<b-row style="max-width: 1440px;" class="pt-5 mx-auto">
				<b-col cols="8">
					<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdp-title-row">
						<b-col cols="12">
							<div class="vdp-title-row-title pb-2">
								{{ carData.pulled.car.title }}
							</div>
							<b-row class="justify-content-start">
								<b-col cols="4">
									<p class="vdp-title-row-vin details-vin">
										Vin: <span class="font-weight-bolder">{{ carData.sent.vin }}</span>
									</p>
								</b-col>
								<b-col cols="4">
									<p class="vdp-title-row-stock details-stock m-0">
										Stock #: <span class="font-weight-bolder">{{ carData.sent.stock_number }}</span>
									</p>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<hr />
					<b-row class="vdp-specsRow-row">
						<b-col cols="12">
							<b-row class="vdp-specsRow-row-title">
								<b-col cols="12">
									<h3 class="vdp-specsRow-row-title-text">Specifications</h3>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3" v-for="( value, key ) in keySpecs" :key="key">
									<div v-if="Array.isArray(value)" class="vdp-specsRow-row-item">
										<div class="vdp-specsRow-row-item-title">{{ key }}</div>
										<div class="vdp-specsRow-row-item-value">
											<p class="vdp-specsRow-row-item-value-text">
												{{ value[0] ? value[0].toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--' }} City* / {{
							value[1] ? value[1].toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--' }} Highway*
											</p>
										</div>
									</div>
									<div v-else-if="key.includes('Color')" class="vdp-specsRow-row-item">
										<div class="vdp-specsRow-row-item-title">{{ key }}</div>
										<div class="vdp-specsRow-row-item-value d-flex justify-content-start align-items-center">
											<span class="colorDot mr-1" :style="{ 'background-color': transformColor(value) }"></span>
											<p class="vdp-specsRow-row-item-value-text mb-0">{{ value }}</p>
										</div>
									</div>
									<div v-else class="vdp-specsRow-row-item">
										<div class="vdp-specsRow-row-item-title">{{ key }}</div>
										<div class="vdp-specsRow-row-item-value">
											<p class="vdp-specsRow-row-item-value-text">{{ value }}</p>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<hr />
					<b-row class="vdp-badge-sash-row">
						<b-col cols="6" class="d-flex align-items-center">
							<div class="iPacketSection d-flex align-content-center justify-contnet-center badge">
								<div class="usedIPacketbutton">
									<a :href="`https://www.ipacket.info/${carData.sent.vin}`" target="_blank">
										<img :src="`https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2A9&vin=${carData.sent.vin}`"
											alt="" loading="lazy" />
									</a>
								</div>
							</div>
						</b-col>
						<b-col cols="3" v-if="vehicle.certification" class="badge">
							<img loading="lazy" class="cpoBadge" :src="cPOBadgeMap[vehicle.certification].src"
								:alt="cPOBadgeMap[vehicle.certification].alt" />
						</b-col>
						<b-col cols="3" class="dealRatingBadge badge">
							<span :data-cg-vin="vehicle.vin" :data-cg-price="vehicle.price"></span>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<hr />
						<b-col cols="12">
							<div id="custom-player-integration" style="width:100%;height:30%;" :data-vin="carData.sent.vin"></div>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="4">
					<b-card class="vdp-side-card">
						<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdp-side-card-price-row">
							<b-col cols="12">
								<!-- *
    	            * Made all prices hidden for new cars and shown for used
    	            * Requested by: Katie 2/6/23
    	            * Updated pricing for 2023 Passport Elite and Ridgelines
    	            * Requested by: Katie 2/13/23
    	            * Updated pricing for all new gasoline cars
    	            * Requested by: Katie 7/29/23
    	          * -->
								<!-- *
    	            * updated price to show for all cars
    	            * Request By: Katie 9/1/23
    	          * -->
								<!-- <div
    	            class="msrp mb-4"
    	            v-if="
    	              ( 
    	                ( carData.pulled.car.new && !['Accord Hybrid', 'CR-V Hybrid'].includes( carData.pulled.car.model ) && [2023, 2024].includes( carData.pulled.car.year ) )
    	              ) && carData.pulled.car.msrp && carData.pulled.car.price
    	              && parseInt( carData.pulled.car.msrp, 10) - parseInt( carData.pulled.car.price, 10 ) > 0
    	            "
    	          > -->
								<!-- <div class="vdp-side-card-price-row-msrp-deal"
									v-if="carData.pulled.car.new && carData.pulled.car.msrp && carData.pulled.car.price && parseInt(carData.pulled.car.msrp, 10) - parseInt(carData.pulled.car.price, 10) > 0">
									<p class="vdp-side-card-price-row-msrp-number-ridge">
										${{ carData.pulled.car.msrp | formatPrices }} <span class="ml-5"
											style="font-size: 14px; font-weight: normal;">MSRP</span>
									</p>
									<p class="vdp-side-card-price-row-msrp-number-ridge">
										-${{ parseInt(carData.pulled.car.msrp, 10) - parseInt(carData.pulled.car.price, 10) |	formatPrices }}
										<span class="ml-5" style="font-size: 16px; font-weight: normal;">
											Dealer Discount
										</span>
									</p>
									<p class="vdp-side-card-price-row-msrp-number-ridge-bold">
										${{ carData.pulled.car.price | formatPrices }} <span class="ml-5"
											style="font-weight: normal;">Now</span>
									</p>
								</div> -->

								<!-- <div class="msrp mb-4" v-else-if="carData.pulled.car.new && ['Accord Hybrid', 'CR-V Hybrid'].includes( carData.pulled.car.model )">
    	            <p class="msrp-non-number">
    	              <a class="callForPrice btn btn-tertiary btn-block btn-sm">
    	                Price Locked
    	              </a>
    	            </p>
    	          </div> -->
								<div>
									<div
										class="vdp-side-card-price-row-msrp-deal"
										v-if="carData.pulled.car.other_price && parseInt(carData.pulled.car.other_price, 10) - parseInt(carData.pulled.car.price, 10) > 0"
									>
										<p class="vdp-side-card-price-row-msrp-number-ridge">
											${{ parseInt(carData.pulled.car.other_price) | formatPrices }} <span class="ml-5"
												style="font-size: 14px; font-weight: normal;">WAS</span>
										</p>
										<p class="vdp-side-card-price-row-msrp-number-ridge">
											-${{ parseInt(carData.pulled.car.other_price, 10) - parseInt(carData.pulled.car.price, 10) | formatPrices }}
											<span class="ml-5" style="font-size: 14px; font-weight: normal;">
												Dealer Discount
											</span>
										</p>
										<p class="vdp-side-card-price-row-msrp-number-ridge-bold">
											<span class="mr-5" style="font-weight: normal;">Now</span>
											${{ carData.pulled.car.price | formatPrices }}
										</p>
									</div>
									<div class="vdp-side-card-price-row-msrp" v-else-if="carData.pulled.car.price">
										<p class="vdp-side-card-price-row-msrp-label">Price</p>
										<p class="vdp-side-card-price-row-msrp-number">
											${{ carData.pulled.car.price | formatPrices }}
										</p>
									</div>
									<div class="vdp-side-card-price-row-msrp" v-else>
										<p class="vdp-side-card-price-row-msrp-non-number">
											<a class="vdp-side-card-price-row-callForPrice btn btn-tertiary btn-block btn-sm">
												Call For More Details
											</a>
										</p>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdp-button-holster-row">
							<b-col class="vdp-button-holster-row-call-button mb-2">
								<a class="btn-moreDetails d-flex align-items-center justify-content-center"
									href="tel:1-559-203-3607">
									<div class="mr-3">
										<b-icon icon="telephone" flip-h font-scale="1.33" style="color: #0072CE"></b-icon>
									</div>
									<span style="color: #0072CE;">
										Call for More Details
									</span>
								</a>
							</b-col>
							<b-col cols="12" class="vdp-button-holster-row-testDrive-button">
								<span style="width: 100%" class="drivemotors modalup-placement" data-ext-dealer-code="2264"
									:data-vin="carData.pulled.car.vin" :data-msrp="carData.pulled.car.msrp"
									:data-price="carData.pulled.car.price" :data-stock-code="carData.pulled.car.stock_number"
									:data-model-num="carData.pulled.car.model" :data-model-trim="carData.pulled.car.trim"
									:data-ext-color="carData.pulled.car.colour" :data-int-color="carData.pulled.car.interior_color"
									:data-is-new="0" :data-miles="carData.pulled.car.odometer
										? carData.pulled.car.odometer
										: 0"
								></span>
								<div class="btn-testDrive btn btn-primary d-flex align-items-center justify-content-center">
									<div class="mr-1">
										<b-icon icon="key-fill" font-scale="1.33"></b-icon>
									</div>
									<span>Schedule A Test Drive</span>
								</div>
							</b-col>
							<b-col cols="12" class="mb-2 p-0">
								<!-- Added CarNow CTA 11/9/23 as recieved by Erica -->
								<div class="cn-button-container" :data-vin="carData.pulled.car.vin" data-page-type="vdp"
									data-type="used"></div>
							</b-col>
							<b-col cols="12" class="vdp-button-holser-row-capitalOne-button p-0">
								<div class="capital-one-wrapper" v-if="carData.pulled.car.price &&
							carData.pulled.car.year > 2007 &&
							carData.pulled.car.odometer < 150000
							">
									<button class="capital-one-prequalification-button pb-0 vdp-button-holser-row-capitalOne-button"
										data-client-token="ee8c2582-7330-4364-84df-9d01e0219e1b"
										:data-sales-price="carData.pulled.car.price" :data-vehicle-image-url="carData.pulled.car.thumbnail"
										:data-vin="carData.pulled.car.vin">
										Explore Financing
									</button>
								</div>
							</b-col>
							<div class="d-flex align-content-center justify-content-center mb-3">
								<p class="disclaimerText">
									Stock images are for illustration purposes only
								</p>
							</div>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<!-- <b-row style="max-width: 1440px;" class="mx-auto">
				<b-col cols="12">
					<b-row v-if="carData.sent.new">
						<b-col cols="12">
							<div id="custom-player-integration" style="width:100%;height:30%;" :data-vin="carData.sent.vin"></div>
						</b-col>
					</b-row>
				</b-col>
			</b-row> -->
			<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" style="max-width: 1440px;" class="mt-3 mx-auto">
				<b-col cols="12">
					<div class="vdp-more-specs-collapse">
						<b-button v-b-toggle.moreSpecs block class="vdp-more-specs-collapse-button">
							All Features
							<b-icon class="ml-3" icon="chevron-down"></b-icon>
						</b-button>
						<b-collapse id="moreSpecs">
							<b-card style="height: unset;" title="Features">
								<b-row v-for="( feature, i ) in carData.pulled.car.featureColumns" :key='i + "_" + feature'>
									<b-col cols="6"
										v-for="( item, i ) in carData.pulled.car.features.split('|').slice(i * 2, i * 2 + 2)"
										:key='i + "_" + item'>
										<li>
											{{ item }}
										</li>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</div>
				</b-col>
			</b-row>
		</div>
		<div id="mobileVersion" class="d-md-none">
			<div class="vdpSlider pt-4">
				<b-row>
					<b-col cols="12">
						<vehicle-images :slides-per-view="1" :photos="carData.sent.photos"
							:car-id="carData.sent.stock_number"></vehicle-images>
					</b-col>
				</b-row>
			</div>
			<b-row class="mobile-vdp-title-row">
				<b-col cols="12">
					<b-button onClick="history.back()"
						class="btn btn-back btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3">
						<b-icon icon="chevron-left"></b-icon>
						<span> Back to Results</span>
					</b-button>
				</b-col>
				<b-col v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" cols="12">
					<div class="vdp-title-row-title pb-2">
						{{ carData.pulled.car.title }}
					</div>
				</b-col>
				<b-col cols="6">
					<p class="vdp-title-row-vin details-vin">
						Vin: <span class="font-weight-bolder">{{ carData.sent.vin }}</span>
					</p>
				</b-col>
				<b-col cols="6">
					<p class="vdp-title-row-stock details-stock m-0" style="text-align:right;">
						Stock #: <span class="font-weight-bolder">{{ carData.sent.stock_number }}</span>
					</p>
				</b-col>
			</b-row>
			<b-row class="mobile-button-row">
				<b-col cols="4">
					<b-button href="#"
						class="btn btn-tertiary btn-icon d-flex justify-content-center align-items-center mr-2 mr-xl-3" style="
              background: linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7 !important;
              box-shadow: 0px 4px 24px !important;
              border-radius: 5px !important;
              font-weight: 600 !important;
							height: 44px;
							width: 100% !important;
            ">
						<b-icon icon="check-circle" class="mr-1"></b-icon>
						<span>Confirm Availability</span>
					</b-button>
				</b-col>
				<b-col cols="4">
					<b-button onclick="window.openAutoHubWidget()"
						class="btn flex justify-content-center align-items-center mr-2 mr-xl-3" style="
							background: linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7;
              box-shadow: 0px 4px 24px;
              border-radius: 5px !important;
              font-weight: 600;
							height: 44px;
							width: 100% !important;
						">
						<b-icon icon="bar-chart-line"></b-icon>
						<span class="header-bottom-popup-value-trade-text">
							<span class="d-none d-md-inline">Value Your</span>
							<span>Trade</span>
						</span>
					</b-button>
				</b-col>
				<b-col cols="4">
					<b-button v-b-modal.vdp-share-modal class="btn flex justify-content-center align-items-center mr-2 mr-xl-3"
						style="
							background: linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7;
              box-shadow: 0px 4px 24px;
              border-radius: 5px !important;
              font-weight: 600;
							height: 44px;
							width: 100% !important;
						">
						<b-icon icon="share-fill"></b-icon>
						<span>Share</span>
						<span class="d-none d-md-inline">This Car</span>
					</b-button>
				</b-col>
			</b-row>
			<b-row class="mobile-vdp-specsRow-row">
				<b-col cols="12">
					<b-row class="vdp-specsRow-row-title">
						<b-col cols="12">
							<h3 class="vdp-specsRow-row-title-text">Specifications</h3>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6" v-for="( value, key ) in keySpecs" :key="key">
							<div v-if="Array.isArray(value)" class="vdp-specsRow-row-item">
								<div class="vdp-specsRow-row-item-title">{{ key }}</div>
								<div class="vdp-specsRow-row-item-value">
									<p class="vdp-specsRow-row-item-value-text">
										{{ value[0] ? value[0].toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--' }} City* / {{
							value[1] ? value[1].toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--' }} Highway*
									</p>
								</div>
							</div>
							<div v-else-if="key.includes('Color')" class="vdp-specsRow-row-item">
								<div class="vdp-specsRow-row-item-title">{{ key }}</div>
								<div class="vdp-specsRow-row-item-value d-flex justify-content-start align-items-center">
									<span class="colorDot mr-1" :style="{ 'background-color': transformColor(value) }"></span>
									<p class="vdp-specsRow-row-item-value-text mb-0">{{ value }}</p>
								</div>
							</div>
							<div v-else class="vdp-specsRow-row-item">
								<div class="vdp-specsRow-row-item-title">{{ key }}</div>
								<div class="vdp-specsRow-row-item-value">
									<p class="vdp-specsRow-row-item-value-text">{{ value }}</p>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<hr />
			<b-row class="vdp-badge-sash-row">
				<b-col cols="12" class="d-flex justify-content-center">
					<div class="iPacketSection badge">
						<div class="newIPacketbutton">
							<a :href="`https://www.ipacket.info/${carData.sent.vin}`" target="_blank">
								<img :src="`https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2AE&vin=${carData.sent.vin}`" alt=""
									loading="lazy" />
							</a>
						</div>
					</div>
				</b-col>
				<b-col cols="6" v-if="vehicle.certification" class="badge">
					<img loading="lazy" class="cpoBadge" :src="cPOBadgeMap[vehicle.certification].src"
						:alt="cPOBadgeMap[vehicle.certification].alt" />
				</b-col>
				<b-col cols="6" class="dealRatingBadge badge">
					<span :data-cg-vin="vehicle.vin" :data-cg-price="vehicle.price"></span>
				</b-col>
			</b-row>
			<hr />
			<b-col cols="12">
				<b-card class="p-4" style="height: unset !important;">
					<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdp-side-card-price-row">
						<b-col cols="12">
							<!-- *
    	          * Made all prices hidden for new cars and shown for used
    	          * Requested by: Katie 2/6/23
    	          * Updated pricing for 2023 Passport Elite and Ridgelines
    	          * Requested by: Katie 2/13/23
    	          * Updated pricing for all new gasoline cars
    	          * Requested by: Katie 7/29/23
    	        * -->
							<!-- *
    	          * updated price to show for all cars
    	          * Request By: Katie 9/1/23
    	        * -->
							<!-- <div
    	          class="msrp mb-4"
    	          v-if="
    	            ( 
    	              ( carData.pulled.car.new && !['Accord Hybrid', 'CR-V Hybrid'].includes( carData.pulled.car.model ) && [2023, 2024].includes( carData.pulled.car.year ) )
    	            ) && carData.pulled.car.msrp && carData.pulled.car.price
    	            && parseInt( carData.pulled.car.msrp, 10) - parseInt( carData.pulled.car.price, 10 ) > 0
    	          "
    	        > -->
							<!-- <div
								class="vdp-side-card-price-row-msrp-deal"
								v-if="carData.pulled.car.new && carData.pulled.car.msrp && carData.pulled.car.price && parseInt(carData.pulled.car.msrp, 10) - parseInt(carData.pulled.car.price, 10) > 0"
							>
								<p class="vdp-side-card-price-row-msrp-number-ridge">
									${{ carData.pulled.car.msrp | formatPrices }} <span class="ml-5"
										style="font-size: 14px; font-weight: normal;">MSRP</span>
								</p>
								<p class="vdp-side-card-price-row-msrp-number-ridge">
									-${{ parseInt(carData.pulled.car.msrp, 10) - parseInt(carData.pulled.car.price, 10) | formatPrices
									}}<span class="ml-5" style="font-size: 16px; font-weight: normal;">Dealer Discount</span>
								</p>
								<p class="vdp-side-card-price-row-msrp-number-ridge-bold">
									${{ carData.pulled.car.price | formatPrices }} <span class="ml-5"
										style="font-weight: normal;">Now</span>
								</p>
							</div> -->

							<!-- <div class="msrp mb-4" v-else-if="carData.pulled.car.new && ['Accord Hybrid', 'CR-V Hybrid'].includes( carData.pulled.car.model )">
    	          <p class="msrp-non-number">
    	            <a class="callForPrice btn btn-tertiary btn-block btn-sm">
    	              Price Locked
    	            </a>
    	          </p>
    	        </div> -->
							
							<!-- <div
                class="vdp-side-card-price-row-msrp"
                v-else-if="carData.pulled.car.new && carData.pulled.car.msrp && carData.pulled.car.price && parseInt( carData.pulled.car.msrp, 10) - parseInt( carData.pulled.car.price, 10 ) < 0"
              >
                <div>
                  <p class="vdp-side-card-price-row-msrp-non-number">
                    <a class="vdp-side-card-price-row-callForPrice btn btn-tertiary btn-block btn-sm" href="tel:1-559-435-5000">
                      Call For Price
                    </a>
                  </p>
                </div>
              </div> -->
							<div>
								<div
									class="vdp-side-card-price-row-msrp-deal"
									v-if="carData.pulled.car.other_price && parseInt(carData.pulled.car.other_price, 10) - parseInt(carData.pulled.car.price, 10) > 0"
								>
									<p class="vdp-side-card-price-row-msrp-number-ridge">
										${{ parseInt(carData.pulled.car.other_price) | formatPrices }} <span class="ml-5"
											style="font-size: 14px; font-weight: normal;">WAS</span>
									</p>
									<p class="vdp-side-card-price-row-msrp-number-ridge">
										-${{ parseInt(carData.pulled.car.other_price, 10) - parseInt(carData.pulled.car.price, 10) | formatPrices }}
										<span class="ml-5" style="font-size: 14px; font-weight: normal;">Dealer Discount</span>
									</p>
									<p class="vdp-side-card-price-row-msrp-number-ridge-bold">
										<span class="mr-5" style="font-weight: normal;">Now</span>${{ carData.pulled.car.price | formatPrices }}
									</p>
								</div>
								<div class="vdp-side-card-price-row-msrp" v-else-if="carData.pulled.car.price">
									<p class="vdp-side-card-price-row-msrp-label">Price</p>
									<p class="vdp-side-card-price-row-msrp-number">
										${{ carData.pulled.car.price | formatPrices }}
									</p>
								</div>
								<div class="vdp-side-card-price-row-msrp" v-else>
									<p class="vdp-side-card-price-row-msrp-non-number">
										<a class="vdp-side-card-price-row-callForPrice btn btn-tertiary btn-block btn-sm">
											Call For More Details
										</a>
									</p>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="vdp-button-holster-row">
						<b-col class="vdp-button-holster-row-call-button mb-2">
							<a
								class="btn-moreDetails d-flex align-items-center justify-content-center"
								href="tel:1-559-203-3607"
							>
								<div class="mr-3">
									<b-icon icon="telephone" flip-h font-scale="1.33" style="color: #0072CE"></b-icon>
								</div>
								<span style="color: #0072CE;">
									Call for More Details
								</span>
							</a>
						</b-col>
						<b-col cols="12" class="vdp-button-holster-row-testDrive-button mb-2">
							<span style="width: 100%" class="drivemotors modalup-placement" data-ext-dealer-code="2264"
								:data-vin="carData.pulled.car.vin" :data-msrp="carData.pulled.car.msrp"
								:data-price="carData.pulled.car.price" :data-stock-code="carData.pulled.car.stock_number"
								:data-model-num="carData.pulled.car.model" :data-model-trim="carData.pulled.car.trim"
								:data-ext-color="carData.pulled.car.colour" :data-int-color="carData.pulled.car.interior_color"
								:data-is-new="0" :data-miles="carData.pulled.car.odometer
								? carData.pulled.car.odometer
								: 0
							"></span>
							<div class="btn-testDrive btn btn-primary d-flex align-items-center justify-content-center">
								<div class="mr-1">
									<b-icon icon="key-fill" font-scale="1.33"></b-icon>
								</div>
								<span>Schedule A Test Drive</span>
							</div>
						</b-col>
						<b-col cols="12" class="mb-3 p-0">
							<!-- Added CarNow CTA 11/9/23 as recieved by Erica -->
							<div
								class="cn-button-container"
								:data-vin="carData.pulled.car.vin"
								data-page-type="vdp"
								data-type="used"
							></div>
						</b-col>
						<b-col cols="12" class="vdp-button-holser-row-capitalOne-button p-0">
							<div class="capital-one-wrapper" v-if="carData.pulled.car.price &&
							carData.pulled.car.year > 2007 &&
							carData.pulled.car.odometer < 150000
							">
								<button class="capital-one-prequalification-button pb-0 vdp-button-holser-row-capitalOne-button"
									data-client-token="ee8c2582-7330-4364-84df-9d01e0219e1b" :data-sales-price="carData.pulled.car.price"
									:data-vehicle-image-url="carData.pulled.car.thumbnail" :data-vin="carData.pulled.car.vin">
									Explore Financing
								</button>
							</div>
						</b-col>
						<hr />
						<div class="d-flex align-content-center justify-content-center mb-3">
							<p class="disclaimerText">
								Stock images are for illustration purposes only
							</p>
						</div>
					</b-row>
				</b-card>
			</b-col>
			<hr />
		</div>
		<b-row style="max-width: 1440px;" class="mx-auto">
			<div class="iPacket-module-section" id="aipModules"></div>
		</b-row>
		<b-row style="max-width: 1440px;" class="mx-auto mb-7">
			<b-col cols="12" lg="6">
				<div class="panel cpoSpecials-section">
					<div class="panel-header full-center">
						<h2>Certified Pre-Owned Honda Specials in Fresno CA</h2>
					</div>
					<div class="panel-body">
						<div class="panel-section warranty padding">
							<p>
								Think you need to drive all over Fresno to find your perfect
								used Honda? Think again! You can find the best pre-owned deals
								on our Certified Pre-Owned Specials page! Shop our Certified
								Pre-Owned Honda specials to save big on the reliable vehicle
								you’d like to take home. We have Certified Pre-Owned Honda SUV
								and Sedan offers you are sure to love at Clawson Honda of
								Fresno.
							</p>
							<p>
								Our finance specialists can help you drive home in a stylish
								vehicle at a great price, regardless of your credit. Our
								Certified Pre-Owned Honda Dealership in Fresno, CA, has a huge
								selection of used cars, trucks and SUVs for sale. Come
								test-drive one of our Certified Pre-Owned specials today!
							</p>
							<div class="text-center mt-5">
								<a class="btn btn-primary" href="/certified-pre-owned-specials/">Certified Pre-Owned Specials</a>
							</div>
						</div>
					</div>
				</div>
				<div v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" class="panel recallLookup-section">
					<div class="panel-body">
						<div class="panel-section warranty padding">
							<h2 class="capitalize text-center">
								Check for unrepaired safety recalls
							</h2>
							<div class="text-center">
								<a v-if="carData.pulled.car.vin" class="btn btn-primary"
									:href="`https://www.nhtsa.gov/recalls?vin=${carData.pulled.car.vin}`" target="_blank">
									NHTSA Recall Look-up
								</a>
								<a v-b-modal.recallModal class="clickable capitalize d-block mt-3">
									<b-icon icon="question-circle"></b-icon>
									Learn about safety recalls
								</a>
							</div>
						</div>
					</div>
				</div>
			</b-col>
			<!-- safety recall modal -->
			<b-modal id="recallModal" busy hide-footer>
				<template #modal-title>
					<h5>
						<b-icon icon="info-circle" class="mr-2"></b-icon>
						Learn About Safety Recalls
					</h5>
				</template>
				<div class="modal-body">
					<p>
						This vehicle may have an open safety recall. A safety recall
						occurs when a manufacturer or the National Highway Traffic
						Safety Administration (NHTSA) finds that a condition or defect
						that could affect the safety or operation of a vehicle may
						exist in a specific vehicle or series of vehicles.
					</p>
					<p>
						Click on the NHTSA Recall Look-Up link to determine if this
						vehicle has an open safety recall.
					</p>
				</div>
			</b-modal>
			<b-col cols="12" lg="6">
				<div class="panel vehicleUpgrade-section">
					<div class="panel-header full-center">
						<h2>Clawson Honda Vehicle Upgrade Program near Fresno, CA</h2>
					</div>
					<div class="panel-body">
						<div class="panel-section warranty padding">
							<p>
								Upgrade Today! Drive off in a newer, safer, more
								technologically advanced Honda for the same or lower monthly
								payment. Why not treat yourself to a new ride? Arrive in style
								with an incredibly affordable and advanced vehicle from our
								Honda dealership near Selma, CA. Right now, we are offering an
								incredible chance for you to enhance your experience behind
								the wheel with the Vehicle Upgrade Program. Come to Clawson
								Honda of Fresno today to take advantage of this outstanding
								offer!
							</p>
							<p>
								Many of our new Honda models qualify for the Vehicle Upgrade
								Program. If you are in the market for a new commuter car, we
								have new Honda Civic sedan and coupe models for sale, or you
								can take a look at the new Honda Accord, too. For those who
								need to fit the whole family, buy or lease a Honda CR-V or
								Pilot. We even have the new Honda Ridgeline and Honda
								Passport, back in stock after a short hiatus. Whichever model
								you have been eyeing, it may qualify for the Clawson Honda
								Vehicle Upgrade Program! Peruse our new Honda inventory before
								you visit our dealership, then come speak with our Honda
								finance and sales teams to learn more about this program. They
								will be ready to help you with any questions you may have, and
								will be there with you throughout the process!
							</p>
							<h4 class="capitalize">
								Other Benefits of Upgrading to a New Honda:
							</h4>
							<ul class="bulletedList">
								<li>Get above market value for your trade-in.</li>
								<li>Expedited sales process.</li>
								<li>A newer vehicle.</li>
								<li>Lower monthly payments.</li>
								<li>Lower fuel costs and increased miles per gallon.</li>
								<li>Better financing terms.</li>
								<li>Reduced maintenance and repair costs.</li>
								<li>Brand new comfort and entertainment features.</li>
								<li>Increased safety and security technology.</li>
							</ul>
							<div class="text-center mt-5">
								<a class="btn btn-primary" href="/vehicle-upgrade-program/">Vehicle Upgrade Program</a>
							</div>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="carData.pulled && Object.keys(carData.pulled).length !== 0 && carData.pulled.constructor === Object" style="max-width: 1440px;" class="mx-auto mb-5">
			<b-col cols="12">
				<b-card class="vdp-related" v-if="carData.pulled.related_cars && carData.pulled.related_cars.length > 0">
					<h1>Related Vehicles</h1>

					<div class="vdp-related-cars">
						<related-vehicles :related_cars="carData.pulled.related_cars"></related-vehicles>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-row style="max-width: 1440px;" class="mx-auto mb-5">
			<b-col cols="12">
				<div class="disclaimer-section mb-4 px-7">
					<p>
						All vehicles are one of each. All Pre-Owned or Certified vehicles are Used. Tax, Title, and Tags are not
						included in vehicle prices shown and must be paid by the purchaser. All offers expire on close of business
						the
						day subject content is removed from website, and all financing is subject to credit approval. All
						transactions
						are negotiable including price, trade allowance, interest rate (of which the dealer may retain a portion),
						term,
						and documentary service fee. $85 dealer documentation charge is non-negotiable. Any agreement is subject to
						execution of contract documents. While great effort is made to ensure the accuracy of the information on
						this
						site, errors do occur so please verify information with a customer service rep. This is easily done by
						calling
						us at 559-435-5000 or by visiting us at the dealership.
					</p>
					<p>
						New and used prices do not include government fees, and taxes, any finance charges, any dealer document
						processing charge, any electronic filing charge, and any emission testing charge. Notice, all of our
						vehicles
						have an anti-theft system installed. The advertised price excludes the purchase of this anti-theft system.
					</p>
					<p>
						**With approved credit. Terms may vary. Monthly payments are only estimates derived from the vehicle price
						with
						a 72-month term, 4.9% interest, and 20% down payment.
					</p>
					<p>
						Some of our used vehicles may be subject to unrepaired safety recalls. Check for a vehicle’s unrepaired
						recalls
						by VIN at <a href="https://vinrcl.safecar.gov/vin/">Check for Recalls Here</a>
					</p>
					<p>
						*MPG on non-hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage
						will
						vary depending on how you drive and maintain your vehicle, driving conditions, and other factors.
					</p>
					<p>
						*MPG for hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will
						vary
						depending on how you drive and maintain your vehicle, driving conditions, battery-pack age/condition (hybrid
						trims), and other factors.
					</p>
					<p>
						While every effort has been made to ensure display of accurate data, the vehicle listings within this web
						site
						may not reflect all accurate vehicle items including photos, information appearing herein are presented and
						declared to the user on as “as-is” basis without any warranties of any kind, either expressed or implied,
						including but not limited to the implied warranties of merchantability. All inventory listed is subject to
						prior
						sale. The vehicle photo displayed may be an example only. Please see the dealer for details. Some vehicles
						shown
						with optional equipment. See the actual vehicle for complete accuracy of features, options & pricing.
						Because of
						the numerous possible combinations of vehicle models, styles, colors and options, the vehicle pictures on
						this
						site may not match your vehicle exactly; however, it will match as closely as possible. Some vehicle images
						shown are stock photos and may not reflect your exact choice of vehicle, color, trim and specification. Not
						responsible for pricing or typographical errors. Please verify individual inventory price(s) with the
						dealer.
					</p>
					<p>
						All information pertaining to any vehicle should be verified through the dealer.
					</p>
					<p>
						*Notice regarding Security System: All of our vehicles are equipped with an anti-theft device to prevent
						theft
						from our inventory. The advertised price for all of our vehicles does not include the price of the
						anti-theft
						device. This device can be purchased for an additional cost, or may be removed at the customer’s option.
					</p>
					<p>
						Any image of a stock photo may not reflect the model year or color of, and/or equipment offered on, the
						advertised vehicle. The image is shown for illustration purposes only.
					</p>
					<p>
						Proposition 65 Warning: Operating, servicing, and maintaining a passenger vehicle or off-road vehicle can
						expose
						you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the
						State
						of California to cause cancer and birth defects or other reproductive harm. To minimize exposure, avoid
						breathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated
						area,
						and wear gloves or wash your hands frequently when servicing your vehicle. For more information go to <a
							href="https://www.p65warnings.ca.gov/products/passenger-vehicle/">p65Warnings</a>
					</p>
					<p>ADM = Added Dealer Markup</p>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { cssColorNames } from '../helpers';

export default {
	props: {
		car: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		finance: {
			taxTitleFees: 0,
			downPayment: 3000,
			totalAmount: 0,
			numOfMonths: 72,
			monthlyPayment: 0,
			interestRateNew: 3.99,
			interestRateUsed: 7.99,
		},
		carData: {
			pulled: {},
			sent: {},
		},
		keySpecs: {},
		cPOBadgeMap: {
			'Certified Pre-Owned': { src: '/static/images/cpoBadge.webp', alt: 'CPO Badge', class: 'search-results-card-badges' },
			'Clawson Customs': { src: '/static/images/Clawson-CC-FNL.webp', alt: 'Clawson Customs Badge', class: 'search-results-card-badges-lg' },
			'Clawson Certified Pre-Owned': { src: '/static/images/Clawson-Certified-HONDA-LIGHT.webp', alt: 'Clawson Certified Pre-Owned Badge', class: 'search-results-card-badges' },
			'HondaTrue Used': { src: '/static/images/hondaTrue/HondaTrue_Used.webp', alt: 'HondaTrue Used Badge', class: 'search-results-card-badges-true' },
			'HondaTrue Certified': { src: '/static/images/hondaTrue/HondaTrue_Certified.webp', alt: 'HondaTrue Certified Badge', class: 'search-results-card-badges-true' },
			'HondaTrue Certified+': { src: '/static/images/hondaTrue/HondaTrue_Certified_Plus.webp', alt: 'HondaTrue Certified+ Badge', class: 'search-results-card-badges-true' },
		},
	}),

	computed: {
		shareLink() {
			const host = window.location.host;
			const path = window.location.pathname;
			return `https://${host}${path}`;
		},
		vehicle() {
			if (!this.car) return {};
			const tempCar = { ...this.car };
			const keys = Object.keys(tempCar);
			keys.forEach(key => {
				if (tempCar[key] == 'null') tempCar[key] = null;
				if (tempCar[key] == 'true') tempCar[key] = true;
				if (tempCar[key] == 'false') tempCar[key] = false;
			});
			return tempCar;
		}
	},

	mounted() {
		axios.get(`/search/${this.vehicle.id}/details/`)
			.then((res) => {
				this.carData.pulled = { ...res.data };
				if (this.carData.pulled.car.photos != null) {
					this.carData.pulled.car.photos = this.carData.pulled.car.photos.split('|');
					this.carData.pulled.car.thumbnail = this.carData.pulled.car.photos[0];
				};

				this.carData.pulled.car.featureColumns = Array.from(Array(Math.ceil(this.carData.pulled.car.features.split("|").length / 2)).keys())

				// Build Key Specs object for page
				this.keySpecs = {
					"Body Style": this.carData.pulled.car.body,
					"Mileage": this.carData.pulled.car.odometer,
					"Transmission": this.carData.pulled.car.transmission,
					"Drivetrain": this.carData.pulled.car.drivetrain_description,
					"Gas Mileage": [this.carData.pulled.car.city_mpg, this.carData.pulled.car.highway_mpg],
					"Fuel Type": this.carData.pulled.car.fuel,
					"Exterior Color": this.carData.pulled.car.colour,
					"Interior Color": this.carData.pulled.car.interior_color,
				};
			})
		// Set carData
		this.carData.sent = { ...this.vehicle };

		// Set Car Photos
		if (this.carData.sent._photos) {
			this.carData.sent.photos = this.carData.sent._photos.split('|');
			this.carData.sent.thumbnail = this.carData.sent.photos[0];
		};

		// Set Car Features
		if (this.carData.sent.features) this.carData.sent.featuresColumns = Array.from(Array(Math.ceil(this.carData.sent.features.split('|').length / 2)).keys());

		// Set title
		let title;
		if (this.carData.sent.certified && this.carData.certified === 'Yes') {
			if (this.carData.sent.cartification === 'Clawson Customs') title = "Custom Pre-Owned cars for Sale | Honda Dealership near Clovis, CA"
			else title = "Certified Pre-Owned Honda cars for Sale | Honda Dealership near Clovis, CA";
		}
		else title = "Pre-Owned cars for Sale | Honda Dealership near Clovis, CA";
		document.title = title;

		// // autoIPacket setup
		// window.autoipakcetSettings = {
		// 	// Set VIN here (Required)
		// 	mqv_vin: this.carData.sent.vin,
		// 	// Set Element id here (Required)
		// 	mqv_element_id: "aipModules",
		// 	// Set API Key here (Required)
		// 	mqv_api_key: 'c5386d89-74a9-4e5d-99fc-ccedcd49c5c4',
		// 	//Default Styles(Optional default true)
		// 	// mqv_styles_enabled: false,
		// 	//Enables On Page Slider Instead of Link(Optional default false)
		// 	mqv_modal_enabled: true,
		// 	//Calls Function on Failure of MQV Load(Optional)
		// 	// mqv_on_error:()=>{},
		// };
		
		// const autoIPacket = document.createElement('script');
		// autoIPacket.src = 'https://s3.amazonaws.com/js.autoipacket.com/moduleQuickViewES5.min.js';
		// autoIPacket.type = 'text/javascript';
		// autoIPacket.async = true;
		// document.getElementsByTagName('body')[0].appendChild(autoIPacket);
		// this.$nextTick(() => {
		// 	const aipModules = document.getElementById('aipModules');
		// 	console.log( 'inside next tick', aipModules );
		// 	if (aipModules)	{
		// 		console.log( 'aipModules exist' );
		// 		aipModules.appendChild(autoIPacket);
		// 	}
		// });

		// CarGurus setup
		const CarGurus = window.CarGurus || {};
		window.CarGurus = CarGurus;
		CarGurus.DealRatingBadge = window.CarGurus.DealRatingBadge || {};
		CarGurus.DealRatingBadge.options = {
			style: "STYLE2",
			minRating: "GOOD_PRICE",
			defaultHeight: "60",
		};
		(function () {
			const script = document.createElement('script');
			script.src = "https://static.cargurus.com/js/api/en_US/1.0/dealratingbadge.js";
			script.async = true;
			const entry = document.getElementsByTagName('script')[0];
			entry.parentNode.insertBefore(script, entry);
		})();
	},
	methods: {
		transformColor(colorInput) {
			if (!colorInput) return "#000000";
			const strippedColor = colorInput.replace(/\s+/g, '').toLowerCase();
			const color = cssColorNames[strippedColor] ? cssColorNames[strippedColor] : "#000000";
			return color;
		},
		getMPGDisclaimer(fuelType) {
			const disclaimerText = {
				Gas: "* Based on 2021 EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your car, driving conditions and other factors.",
				Hybrid: "* Based on 2021 EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on how you drive and maintain your car, driving conditions, battery-pack age/condition (hybrid trims) and other factors.",
			};
			const disclaimer = fuelType.toLowerCase().includes('hybrid') ? disclaimerText.Hybrid : fuelType.toLowerCase().includes('electric') ? disclaimerText.Hybrid : disclaimerText.Gas;
			return disclaimer;
		},
		copyLink() {
			if (navigator.share) {
				navigator.share({
					title: ` Clawson Honda -- ${this.carData.pulled.car.title}`,
					text: 'Check out this car!',
					url: this.shareLink,
				}).catch(console.error);
				return;
			}
			navigator.clipboard.writeText(this.shareLink);
			this.makeToast();
		},
		makeToast() {
			this.$bvToast.toast('Copy Link Success!', {
				title: 'Success',
				variant: 'success',
				solid: true,
				toaster: navigator.orientation > 1 ? 'b-toaster-top-center' : 'b-toaster-top-right',
			});
		},
	},
	filters: {
		formatPrices: (number) => {
			if (number != null) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	},
};
</script>