<template>
  <div class="details-top-car-pictures-side-carousel carousel-container small">
    <swiper-container
      class="details-top-car-pictures-side-carousel"
      :slides-per-view="slidesPerView"
      :space-between="0"
      :centered-slides="true"
      :loop="true"
      :initial-slide="slidesPerView === 1 ? 0 : 1"
      :navigation="true"
    >
      <swiper-slide
        class="details-top-car-pictures-side-picture-container"
        v-for="( photo, i ) in photos"
        :key="i"
      >
        <img
          class="details-top-car-pictures-side-picture"
          :src="photo ? photo : ['U','T'].includes( carId.split('')[0] ) ? '/static/images/tCComingSoon.webp' : '/static/images/hComingSoon.webp'"
          alt=""
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>


<script>
  export default {
    props: {
     photos : {
       type: Array,
       required: true
     },
     carId : {
       type: String,
       required: true
     },
     slidesPerView: {
       type: Number,
       default: 3
     },
    },
    methods: {
      viewFullscreen: function(url){
        $('.details-top-car-pictures-side-carousel-fullscreen-picture').attr("src", url);
        $('#fullscreen-image-modal').modal();
      }
    }

  }
</script>
