var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ais-instant-search",
    {
      attrs: {
        "index-name": _vm.indexName,
        "search-client": _vm.searchClient,
        routing: _vm.routing
      }
    },
    [
      !_vm.detailsPage.viewing
        ? _c("div", { staticClass: "searchPage container-fluid pb-4" }, [
            _c(
              "div",
              { staticClass: "container page-container" },
              [
                _c("ais-configure", { attrs: { hitsPerPage: 12 } }),
                _vm._v(" "),
                _c("div", { staticClass: "row pb-2" }, [
                  _c("div", { attrs: { id: "autoHubBanner" } })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row pt-2 pb-0 pb-sm-2 justify-content-between",
                    attrs: { id: "filterSection" }
                  },
                  [
                    _c("ais-menu", {
                      staticClass: "newFilterMenu",
                      attrs: { attribute: "new" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var refine = ref.refine
                            var createURL = ref.createURL
                            return _c(
                              "ul",
                              { staticClass: "condition-new-filters" },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      style: {
                                        fontWeight: _vm.conditionNew
                                          ? "bold"
                                          : "",
                                        background: _vm.conditionNew
                                          ? "#0072CE"
                                          : "",
                                        color: _vm.conditionNew
                                          ? "#FFFFFF"
                                          : "#0072CE",
                                        pointerEvents: _vm.conditionNew
                                          ? "none"
                                          : "all"
                                      },
                                      attrs: { href: createURL("true") },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.refineCondition(
                                            refine,
                                            "true"
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("New")])]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      style: {
                                        fontWeight: !_vm.conditionNew
                                          ? "bold"
                                          : "",
                                        background: !_vm.conditionNew
                                          ? "#0072CE"
                                          : "",
                                        color: !_vm.conditionNew
                                          ? "#FFFFFF"
                                          : "#0072CE",
                                        pointerEvents: !_vm.conditionNew
                                          ? "none"
                                          : "all"
                                      },
                                      attrs: { href: createURL("false") },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.refineCondition(
                                            refine,
                                            "false"
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("Used")])]
                                  )
                                ])
                              ]
                            )
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("ais-sort-by", {
                      staticClass: "clawson-sort-by",
                      attrs: {
                        attribute: "sortBy",
                        items: [
                          { value: _vm.indexName, label: "Sort by" },
                          {
                            value: _vm.indexName + "_price_asc",
                            label: "Price (Lowest)"
                          },
                          {
                            value: _vm.indexName + "_price_desc",
                            label: "Price (Highest)"
                          },
                          {
                            value: _vm.indexName + "_year_desc",
                            label: "Year (Newest)"
                          },
                          {
                            value: _vm.indexName + "_year_asc",
                            label: "Year (Oldest)"
                          },
                          {
                            value: _vm.indexName + "_mileage_asc",
                            label: "Mileage (Lowest)"
                          },
                          {
                            value: _vm.indexName + "_mileage_desc",
                            label: "Mileage (Highest)"
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("ais-search-box", {
                      staticClass: "mt-3 mb-2 mb-sm-0 my-md-0",
                      attrs: { placeholder: "Search all results" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row pt-2 filterSection" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-none d-lg-block dropdown filterDropdown"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "certificationFilterTrigger",
                            type: "button",
                            "data-toggle": "dropdown"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [_vm._v("\n            Certification\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: {
                            "aria-labelledby": "certificationFilterTrigger"
                          }
                        },
                        [
                          _c("ais-panel", {
                            staticClass: "mb-4",
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function() {
                                  return [_c("h6", [_vm._v("Certification")])]
                                },
                                proxy: true
                              },
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("ais-refinement-list", {
                                      attrs: {
                                        attribute: "certification",
                                        "transform-itmes":
                                          _vm.transformCertification
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var items = ref.items
                                            var refine = ref.refine
                                            var createURL = ref.createURL
                                            return [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "ais-RefinementList-list certified-filter-list"
                                                },
                                                _vm._l(items, function(item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.value,
                                                      staticClass:
                                                        "ais-RefinementList-item",
                                                      class: {
                                                        "ais-RefinementList-item--selected":
                                                          item.isRefined
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "ais-RefinementList-Checkbox",
                                                          style: {
                                                            fontWeight: item.isRefined
                                                              ? "bold"
                                                              : ""
                                                          },
                                                          attrs: {
                                                            href: createURL(
                                                              item.value
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return refine(
                                                                item.value
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          item.label ==
                                                          "Certified Pre-Owned"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Certified Pre-Owned"
                                                                  )
                                                                ]
                                                              )
                                                            : item.label ==
                                                              "Clawson Certified Pre-Owned"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Clawson Certified Pre-Owned"
                                                                  )
                                                                ]
                                                              )
                                                            : item.label ==
                                                              "Clawson Customs"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Clawson Customs"
                                                                  )
                                                                ]
                                                              )
                                                            : item.label ==
                                                              "HondaTrue Used"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "HondaTrue Used"
                                                                  )
                                                                ]
                                                              )
                                                            : item.label ==
                                                              "HondaTrue Certified"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "HondaTrue Certified"
                                                                  )
                                                                ]
                                                              )
                                                            : item.label ==
                                                              "HondaTrue Certified+"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "HondaTrue Certified+"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-count"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.count
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "footer",
                                fn: function() {
                                  return [
                                    _vm.isMobile
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary btn-block mt-4",
                                            on: { click: _vm.closeDropdown }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Apply Filters\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-none d-lg-block dropdown filterDropdown"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "makeModelFilterTrigger",
                            type: "button",
                            "data-toggle": "dropdown"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "d-none d-lg-inline" }, [
                            _vm._v("Make, Model & Trim")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "d-none d-md-inline d-lg-none" },
                            [_vm._v("Make/Model/Trim")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "d-inline d-md-none" }, [
                            _vm._v("Make")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu lg-menu",
                          attrs: {
                            id: "makeModelMenu",
                            "aria-labelledby": "makeModelFilterTrigger"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c("ais-panel", {
                                staticClass: "mb-4",
                                scopedSlots: _vm._u([
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [_c("h6", [_vm._v("Make")])]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("ais-refinement-list", {
                                          attrs: {
                                            attribute: "make",
                                            limit: 100
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var items = ref.items
                                                var refine = ref.refine
                                                var createURL = ref.createURL
                                                return _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "ais-RefinementList-list"
                                                  },
                                                  _vm._l(items, function(item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.value,
                                                        staticClass:
                                                          "ais-RefinementList-item",
                                                        class: {
                                                          "ais-RefinementList-item--selected":
                                                            item.isRefined
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "ais-RefinementList-checkbox",
                                                            style: {
                                                              fontWeight: item.isRefined
                                                                ? "bold"
                                                                : ""
                                                            },
                                                            attrs: {
                                                              href: createURL(
                                                                item.value
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return refine(
                                                                  item.value
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.label
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-count"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }),
                                                  0
                                                )
                                              }
                                            }
                                          ])
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("ais-panel", {
                                staticClass: "mb-4",
                                scopedSlots: _vm._u([
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [_c("h6", [_vm._v("Model")])]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("ais-refinement-list", {
                                          attrs: {
                                            attribute: "model",
                                            limit: 40
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var items = ref.items
                                                var refine = ref.refine
                                                var createURL = ref.createURL
                                                return _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "ais-RefinementList-list"
                                                  },
                                                  _vm._l(items, function(item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.value,
                                                        staticClass:
                                                          "ais-RefinementList-item",
                                                        class: {
                                                          "ais-RefinementList-item--selected":
                                                            item.isRefined
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "ais-RefinementList-checkbox",
                                                            style: {
                                                              fontWeight: item.isRefined
                                                                ? "bold"
                                                                : ""
                                                            },
                                                            attrs: {
                                                              href: createURL(
                                                                item.value
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return refine(
                                                                  item.value
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.label
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-count"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }),
                                                  0
                                                )
                                              }
                                            }
                                          ])
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("ais-panel", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [_c("h6", [_vm._v("Trim")])]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("ais-refinement-list", {
                                          attrs: { attribute: "trim" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var items = ref.items
                                                var refine = ref.refine
                                                var createURL = ref.createURL
                                                return _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "ais-RefinementList-list"
                                                  },
                                                  _vm._l(items, function(item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.value,
                                                        staticClass:
                                                          "ais-RefinementList-item",
                                                        class: {
                                                          "ais-RefinementList-item--selected":
                                                            item.isRefined
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "ais-RefinementList-checkbox",
                                                            style: {
                                                              fontWeight: item.isRefined
                                                                ? "bold"
                                                                : ""
                                                            },
                                                            attrs: {
                                                              href: createURL(
                                                                item.value
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return refine(
                                                                  item.value
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.label
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ais-RefinementList-count"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }),
                                                  0
                                                )
                                              }
                                            }
                                          ])
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isMobile
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "applyMakeModelButton mt-4 btn btn-block btn-primary",
                                  on: { click: _vm.closeDropdown }
                                },
                                [
                                  _vm._v(
                                    "\n              Apply Filters\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-none d-lg-block dropdown filterDropdown"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "priceFilterTrigger",
                            type: "button",
                            "data-toggle": "dropdown"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [_vm._v("\n            Price\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "priceFilterTrigger" }
                        },
                        [
                          _c(
                            "ais-panel",
                            [
                              _c(
                                "div",
                                { staticClass: "rangeInputWrapper" },
                                [
                                  _c("h6", [_vm._v("Price")]),
                                  _vm._v(" "),
                                  _c("ais-range-input", {
                                    attrs: { attribute: "price" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var currentRefinement =
                                            ref.currentRefinement
                                          var range = ref.range
                                          var refine = ref.refine
                                          return _c("div", {}, [
                                            _c(
                                              "select",
                                              {
                                                attrs: { name: "priceMin" },
                                                on: {
                                                  change: function($event) {
                                                    refine({
                                                      min:
                                                        $event.currentTarget
                                                          .value,
                                                      max: _vm.formatMaxValue(
                                                        currentRefinement.max,
                                                        range.max
                                                      )
                                                    })
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      selected: ""
                                                    }
                                                  },
                                                  [_vm._v("Min")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "0" } },
                                                  [_vm._v("No Min")]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(_vm.priceRange, function(
                                                  price
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: price.value,
                                                      domProps: {
                                                        selected:
                                                          currentRefinement.min ==
                                                          price.value,
                                                        value: price.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(price.text) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("-")]),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                attrs: { name: "priceMax" },
                                                on: {
                                                  change: function($event) {
                                                    refine({
                                                      min: _vm.formatMinValue(
                                                        currentRefinement.min,
                                                        range.min
                                                      ),
                                                      max:
                                                        $event.currentTarget
                                                          .value
                                                    })
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      selected: ""
                                                    }
                                                  },
                                                  [_vm._v("Max")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "90000" } },
                                                  [_vm._v("No Max")]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(_vm.priceRange, function(
                                                  price
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: price.value,
                                                      domProps: {
                                                        selected:
                                                          currentRefinement.max ==
                                                          price.value,
                                                        value: price.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(price.text) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          ])
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("ais-range-input", {
                                attrs: { attribute: "price" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var currentRefinement =
                                        ref.currentRefinement
                                      var range = ref.range
                                      var refine = ref.refine
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c("vue-slider", {
                                            ref: "slider2",
                                            attrs: {
                                              tooltip: "none",
                                              min: range.min,
                                              max: range.max,
                                              lazy: false,
                                              contained: true,
                                              value: _vm.toValue(
                                                currentRefinement,
                                                range
                                              ),
                                              marks: [range.min, range.max],
                                              railStyle: {
                                                backgroundColor: "#dfdfdf"
                                              },
                                              processStyle: {
                                                backgroundColor: "#0072ce"
                                              },
                                              labelStyle: {
                                                marginTop: "1.25em",
                                                color: "#939393",
                                                fontSize: ".9rem"
                                              },
                                              enableCross: false
                                            },
                                            on: {
                                              "drag-start": function($event) {
                                                return _vm.updateSlider2()
                                              },
                                              change: function($event) {
                                                return refine({
                                                  min: $event[0],
                                                  max: $event[1]
                                                })
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "dot",
                                                  fn: function(ref) {
                                                    var focus = ref.focus
                                                    return [
                                                      _c("div", {
                                                        class: [
                                                          "custom-dot",
                                                          { focus: focus }
                                                        ]
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isMobile
                            ? _c(
                                "button",
                                {
                                  staticClass: "mt-5 btn btn-block btn-primary",
                                  on: { click: _vm.closeDropdown }
                                },
                                [
                                  _vm._v(
                                    "\n              Apply Filters\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown filterDropdown d-none d-lg-block"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "yearFilterTrigger",
                            type: "button",
                            "data-toggle": "dropdown"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [_vm._v("\n            Year & Mileage\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "yearFilterTrigger" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "ais-panel",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "rangeInputWrapper" },
                                    [
                                      _c("h6", [_vm._v("Year")]),
                                      _vm._v(" "),
                                      _c("ais-range-input", {
                                        attrs: { attribute: "year" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var currentRefinement =
                                                ref.currentRefinement
                                              var range = ref.range
                                              var refine = ref.refine
                                              return _c("div", {}, [
                                                _c(
                                                  "select",
                                                  {
                                                    attrs: { name: "yearMin" },
                                                    on: {
                                                      change: function($event) {
                                                        refine({
                                                          min:
                                                            $event.currentTarget
                                                              .value,
                                                          max: _vm.formatMaxValue(
                                                            currentRefinement.max,
                                                            range.max
                                                          )
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [_vm._v("Min")]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(_vm.years, function(
                                                      year
                                                    ) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: year,
                                                          domProps: {
                                                            selected:
                                                              currentRefinement.min ==
                                                              year,
                                                            value: year
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(year) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("-")]),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    attrs: { name: "yearMax" },
                                                    on: {
                                                      change: function($event) {
                                                        refine({
                                                          min: _vm.formatMinValue(
                                                            currentRefinement.min,
                                                            range.min
                                                          ),
                                                          max:
                                                            $event.currentTarget
                                                              .value
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [_vm._v("Max")]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(_vm.years, function(
                                                      year
                                                    ) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: year,
                                                          domProps: {
                                                            selected:
                                                              currentRefinement.max ==
                                                              year,
                                                            value: year
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(year) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  ],
                                                  2
                                                )
                                              ])
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("ais-range-input", {
                                    staticClass: "mb-6",
                                    attrs: { attribute: "year" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var currentRefinement =
                                            ref.currentRefinement
                                          var range = ref.range
                                          var refine = ref.refine
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              _c("vue-slider", {
                                                ref: "slider1",
                                                attrs: {
                                                  tooltip: "none",
                                                  min: range.min,
                                                  max: range.max,
                                                  lazy: false,
                                                  contained: true,
                                                  value: _vm.toValue(
                                                    currentRefinement,
                                                    range
                                                  ),
                                                  marks: [range.min, range.max],
                                                  railStyle: {
                                                    backgroundColor: "#dfdfdf"
                                                  },
                                                  processStyle: {
                                                    backgroundColor: "#0072ce"
                                                  },
                                                  absorb: true,
                                                  labelStyle: {
                                                    marginTop: "1.25em",
                                                    color: "#939393",
                                                    fontSize: ".9rem"
                                                  },
                                                  enableCross: false
                                                },
                                                on: {
                                                  "drag-start": function(
                                                    $event
                                                  ) {
                                                    return _vm.updateSlider1()
                                                  },
                                                  change: function($event) {
                                                    return refine({
                                                      min: $event[0],
                                                      max: $event[1]
                                                    })
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "dot",
                                                      fn: function(ref) {
                                                        var focus = ref.focus
                                                        return [
                                                          _c("div", {
                                                            class: [
                                                              "custom-dot",
                                                              { focus: focus }
                                                            ]
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ais-panel",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "rangeInputWrapper" },
                                    [
                                      _c("h6", [_vm._v("Mileage")]),
                                      _vm._v(" "),
                                      _c("ais-range-input", {
                                        attrs: { attribute: "mileage" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var currentRefinement =
                                                ref.currentRefinement
                                              var range = ref.range
                                              var refine = ref.refine
                                              return _c("div", {}, [
                                                _c(
                                                  "select",
                                                  {
                                                    attrs: {
                                                      name: "mileageMin"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        refine({
                                                          min:
                                                            $event.currentTarget
                                                              .value,
                                                          max: _vm.formatMaxValue(
                                                            currentRefinement.max,
                                                            range.max
                                                          )
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [_vm._v("Min")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "0" } },
                                                      [_vm._v("No Min")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "0" } },
                                                      [_vm._v("0")]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.mileageRange,
                                                      function(miles) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: miles.value,
                                                            domProps: {
                                                              selected:
                                                                currentRefinement.min ==
                                                                miles.value,
                                                              value: miles.value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  miles.value
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("-")]),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    attrs: {
                                                      name: "mileageMax"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        refine({
                                                          min: _vm.formatMinValue(
                                                            currentRefinement.min,
                                                            range.min
                                                          ),
                                                          max:
                                                            $event.currentTarget
                                                              .value
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [_vm._v("Max")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "0" } },
                                                      [_vm._v("0")]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.mileageRange,
                                                      function(miles) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: miles.value,
                                                            domProps: {
                                                              selected:
                                                                currentRefinement.max ==
                                                                miles.value,
                                                              value: miles.value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  miles.value
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ])
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("ais-range-input", {
                                    attrs: { attribute: "mileage" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var currentRefinement =
                                            ref.currentRefinement
                                          var range = ref.range
                                          var refine = ref.refine
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              _c("vue-slider", {
                                                ref: "slider5",
                                                attrs: {
                                                  tooltip: "none",
                                                  min: range.min,
                                                  max: range.max,
                                                  lazy: false,
                                                  contained: true,
                                                  value: _vm.toValue(
                                                    currentRefinement,
                                                    range
                                                  ),
                                                  marks: [range.min, range.max],
                                                  railStyle: {
                                                    backgroundColor: "#dfdfdf"
                                                  },
                                                  processStyle: {
                                                    backgroundColor: "#0072ce"
                                                  },
                                                  absorb: true,
                                                  labelStyle: {
                                                    marginTop: "1.25em",
                                                    color: "#939393",
                                                    fontSize: ".9rem"
                                                  },
                                                  enableCross: false
                                                },
                                                on: {
                                                  "drag-start": function(
                                                    $event
                                                  ) {
                                                    return _vm.updateSlider5()
                                                  },
                                                  change: function($event) {
                                                    return refine({
                                                      min: $event[0],
                                                      max: $event[1]
                                                    })
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "dot",
                                                      fn: function(ref) {
                                                        var focus = ref.focus
                                                        return [
                                                          _c("div", {
                                                            class: [
                                                              "custom-dot",
                                                              { focus: focus }
                                                            ]
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isMobile
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "applyBodyButton mt-4 btn btn-block btn-primary",
                                      on: { click: _vm.closeDropdown }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Apply Filters\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-none d-lg-block dropdown filterDropdown"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "bodyTypeFilterTrigger",
                            type: "button",
                            "data-toggle": "dropdown"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "d-none d-lg-inline" }, [
                            _vm._v("Body Type")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "d-inline d-lg-none" }, [
                            _vm._v("Body")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu md-menu",
                          attrs: { "aria-labelledby": "bodyTypeFilterTrigger" }
                        },
                        [
                          _c("ais-panel", {
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function() {
                                  return [_c("h6", [_vm._v("Body Type")])]
                                },
                                proxy: true
                              },
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("ais-refinement-list", {
                                      attrs: { attribute: "body" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var items = ref.items
                                            var refine = ref.refine
                                            var createURL = ref.createURL
                                            return _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "ais-RefinementList-list"
                                              },
                                              _vm._l(items, function(item) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: item.value,
                                                    staticClass:
                                                      "ais-RefinementList-item",
                                                    class: {
                                                      "ais-RefinementList-item--selected":
                                                        item.isRefined
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "ais-RefinementList-checkbox",
                                                        style: {
                                                          fontWeight: item.isRefined
                                                            ? "bold"
                                                            : ""
                                                        },
                                                        attrs: {
                                                          href: createURL(
                                                            item.value
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return refine(
                                                              item.value
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ais-RefinementList-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.label)
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ais-RefinementList-count"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.count)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          }
                                        }
                                      ])
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _vm.isMobile
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "applyBodyButton mt-4 btn btn-block btn-primary",
                                  on: { click: _vm.closeDropdown }
                                },
                                [
                                  _vm._v(
                                    "\n              Apply Filters\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown filterDropdown d-none d-lg-block showMore"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "showMoreTrigger",
                            type: "button",
                            "data-toggle": "dropdown",
                            "data-flip": "false"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "d-none d-lg-inline" }, [
                            _vm._v("Show More")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "d-inline d-lg-none" }, [
                            _vm._v("More")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu dropdown-menu-right xl-menu",
                          attrs: { "aria-labelledby": "showMoreTrigger" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6 d-block d-lg-none" },
                              [
                                _c(
                                  "ais-panel",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "rangeInputWrapper" },
                                      [
                                        _c("h6", [_vm._v("Year")]),
                                        _vm._v(" "),
                                        _c(
                                          "ais-range-input",
                                          { attrs: { attribute: "year" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "separator" },
                                                slot: "separator"
                                              },
                                              [_vm._v("-")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ais-range-input", {
                                      attrs: { attribute: "year" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var currentRefinement =
                                              ref.currentRefinement
                                            var range = ref.range
                                            var refine = ref.refine
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c("vue-slider", {
                                                  ref: "slider1",
                                                  attrs: {
                                                    tooltip: "none",
                                                    min: range.min,
                                                    max: range.max,
                                                    lazy: false,
                                                    contained: true,
                                                    value: _vm.toValue(
                                                      currentRefinement,
                                                      range
                                                    ),
                                                    marks: [
                                                      range.min,
                                                      range.max
                                                    ],
                                                    railStyle: {
                                                      backgroundColor: "#dfdfdf"
                                                    },
                                                    processStyle: {
                                                      backgroundColor: "#0072ce"
                                                    },
                                                    absorb: true,
                                                    labelStyle: {
                                                      marginTop: "1.25em",
                                                      color: "#939393",
                                                      fontSize: ".9rem"
                                                    },
                                                    enableCross: false
                                                  },
                                                  on: {
                                                    "drag-start": function(
                                                      $event
                                                    ) {
                                                      return _vm.updateSlider1()
                                                    },
                                                    change: function($event) {
                                                      return refine({
                                                        min: $event[0],
                                                        max: $event[1]
                                                      })
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "dot",
                                                        fn: function(ref) {
                                                          var focus = ref.focus
                                                          return [
                                                            _c("div", {
                                                              class: [
                                                                "custom-dot",
                                                                { focus: focus }
                                                              ]
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 d-block d-lg-none" },
                              [
                                _c(
                                  "ais-panel",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "rangeInputWrapper" },
                                      [
                                        _c("h6", [_vm._v("Mileage")]),
                                        _vm._v(" "),
                                        _c(
                                          "ais-range-input",
                                          { attrs: { attribute: "mileage" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "separator" },
                                                slot: "separator"
                                              },
                                              [_vm._v("-")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ais-range-input", {
                                      attrs: { attribute: "mileage" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var currentRefinement =
                                              ref.currentRefinement
                                            var range = ref.range
                                            var refine = ref.refine
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c("vue-slider", {
                                                  ref: "slider5",
                                                  attrs: {
                                                    tooltip: "none",
                                                    min: range.min,
                                                    max: range.max,
                                                    lazy: false,
                                                    contained: true,
                                                    value: _vm.toValue(
                                                      currentRefinement,
                                                      range
                                                    ),
                                                    marks: [
                                                      range.min,
                                                      range.max
                                                    ],
                                                    railStyle: {
                                                      backgroundColor: "#dfdfdf"
                                                    },
                                                    processStyle: {
                                                      backgroundColor: "#0072ce"
                                                    },
                                                    absorb: true,
                                                    labelStyle: {
                                                      marginTop: "1.25em",
                                                      color: "#939393",
                                                      fontSize: ".9rem"
                                                    },
                                                    enableCross: false
                                                  },
                                                  on: {
                                                    "drag-start": function(
                                                      $event
                                                    ) {
                                                      return _vm.updateSlider5()
                                                    },
                                                    change: function($event) {
                                                      return refine({
                                                        min: $event[0],
                                                        max: $event[1]
                                                      })
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "dot",
                                                        fn: function(ref) {
                                                          var focus = ref.focus
                                                          return [
                                                            _c("div", {
                                                              class: [
                                                                "custom-dot",
                                                                { focus: focus }
                                                              ]
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", {
                              staticClass: "divider d-block d-lg-none"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c(
                                  "ais-panel",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "rangeInputWrapper" },
                                      [
                                        _c("h6", [_vm._v("MPG (HWY)")]),
                                        _vm._v(" "),
                                        _c(
                                          "ais-range-input",
                                          {
                                            attrs: { attribute: "highway_mpg" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "separator" },
                                                slot: "separator"
                                              },
                                              [_vm._v("-")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ais-range-input", {
                                      attrs: { attribute: "highway_mpg" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var currentRefinement =
                                              ref.currentRefinement
                                            var range = ref.range
                                            var refine = ref.refine
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c("vue-slider", {
                                                  ref: "slider3",
                                                  attrs: {
                                                    tooltip: "none",
                                                    min: range.min,
                                                    max: range.max,
                                                    lazy: false,
                                                    contained: true,
                                                    value: _vm.toValue(
                                                      currentRefinement,
                                                      range
                                                    ),
                                                    marks: [
                                                      range.min,
                                                      range.max
                                                    ],
                                                    railStyle: {
                                                      backgroundColor: "#dfdfdf"
                                                    },
                                                    processStyle: {
                                                      backgroundColor: "#0072ce"
                                                    },
                                                    absorb: true,
                                                    labelStyle: {
                                                      marginTop: "1.25em",
                                                      color: "#939393",
                                                      fontSize: ".9rem"
                                                    },
                                                    enableCross: false
                                                  },
                                                  on: {
                                                    "drag-start": function(
                                                      $event
                                                    ) {
                                                      return _vm.updateSlider3()
                                                    },
                                                    change: function($event) {
                                                      return refine({
                                                        min: $event[0],
                                                        max: $event[1]
                                                      })
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "dot",
                                                        fn: function(ref) {
                                                          var focus = ref.focus
                                                          return [
                                                            _c("div", {
                                                              class: [
                                                                "custom-dot",
                                                                { focus: focus }
                                                              ]
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c(
                                  "ais-panel",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "rangeInputWrapper" },
                                      [
                                        _c("h6", [_vm._v("MPG (CITY)")]),
                                        _vm._v(" "),
                                        _c(
                                          "ais-range-input",
                                          { attrs: { attribute: "city_mpg" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "separator" },
                                                slot: "separator"
                                              },
                                              [_vm._v("-")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ais-range-input", {
                                      attrs: { attribute: "city_mpg" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var currentRefinement =
                                              ref.currentRefinement
                                            var range = ref.range
                                            var refine = ref.refine
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c("vue-slider", {
                                                  ref: "slider4",
                                                  attrs: {
                                                    tooltip: "none",
                                                    min: range.min,
                                                    max: range.max,
                                                    lazy: false,
                                                    contained: true,
                                                    value: _vm.toValue(
                                                      currentRefinement,
                                                      range
                                                    ),
                                                    marks: [
                                                      range.min,
                                                      range.max
                                                    ],
                                                    railStyle: {
                                                      backgroundColor: "#dfdfdf"
                                                    },
                                                    processStyle: {
                                                      backgroundColor: "#0072ce"
                                                    },
                                                    absorb: true,
                                                    labelStyle: {
                                                      marginTop: "1.25em",
                                                      color: "#939393",
                                                      fontSize: ".9rem"
                                                    },
                                                    enableCross: false
                                                  },
                                                  on: {
                                                    "drag-start": function(
                                                      $event
                                                    ) {
                                                      return _vm.updateSlider4()
                                                    },
                                                    change: function($event) {
                                                      return refine({
                                                        min: $event[0],
                                                        max: $event[1]
                                                      })
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "dot",
                                                        fn: function(ref) {
                                                          var focus = ref.focus
                                                          return [
                                                            _c("div", {
                                                              class: [
                                                                "custom-dot",
                                                                { focus: focus }
                                                              ]
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-xl-4" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("h6", [_vm._v("Drive Train")])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: {
                                              attribute:
                                                "drivetrain_description"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-xl-4" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [_c("h6", [_vm._v("Cylinders")])]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: { attribute: "cylinders" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-xl-4" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("h6", [_vm._v("Door Count")])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: { attribute: "door_count" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-xl-6" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [_c("h6", [_vm._v("Color")])]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: {
                                              attribute: "color",
                                              "transform-items":
                                                _vm.transformColors
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item colorItem",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox colorLink",
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              item.label.includes(
                                                                "White"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText whiteLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              White\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Black"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText blackLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Black\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Red"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText redLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Red\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Silver"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText silverLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Silver\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Blue"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText blueLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Blue\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Gray"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText grayLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Gray\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.label.includes(
                                                                "Steel"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ais-RefinementList-labelText steelLabel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "swatch"
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              Steel\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        item.count
                                                                      ) +
                                                                      "\n                            "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-xl-6" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("h6", [
                                            _vm._v("Interior Material")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: {
                                              attribute: "interior_material"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [_c("h6", [_vm._v("Fuel Type")])]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: { attribute: "fuel" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("h6", [_vm._v("Transmission")])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: {
                                              attribute: "transmission"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [_c("h6", [_vm._v("Engine")])]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: { attribute: "engine" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("ais-panel", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [_c("h6", [_vm._v("Features")])]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("ais-refinement-list", {
                                            attrs: {
                                              limit: 8,
                                              attribute: "feature_list",
                                              "show-more": true,
                                              "show-more-limit": 50,
                                              "sort-by": ["isRefined"]
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  var refine = ref.refine
                                                  var createURL = ref.createURL
                                                  return _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "ais-RefinementList-list"
                                                    },
                                                    _vm._l(items, function(
                                                      item
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.value,
                                                          staticClass:
                                                            "ais-RefinementList-item",
                                                          class: {
                                                            "ais-RefinementList-item--selected":
                                                              item.isRefined
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ais-RefinementList-checkbox",
                                                              style: {
                                                                fontWeight: item.isRefined
                                                                  ? "bold"
                                                                  : ""
                                                              },
                                                              attrs: {
                                                                href: createURL(
                                                                  item.value
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return refine(
                                                                    item.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-count"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.count
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }
                                              }
                                            ])
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown filterDropdown d-block d-lg-none showFilters"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filterBtn",
                          attrs: {
                            id: "showFiltersTrigger",
                            type: "button",
                            "data-target": "#showFiltersModal",
                            "data-toggle": "modal"
                          },
                          on: {
                            click: function($event) {
                              return _vm.scrollToFilter()
                            }
                          }
                        },
                        [_vm._v("\n            Filters\n          ")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal fade p-0",
                      attrs: {
                        id: "showFiltersModal",
                        tabindex: "-1",
                        "aria-labelledby": "showFiltersTrigger",
                        "aria-hidden": "true"
                      }
                    },
                    [
                      _c("div", { staticClass: "modal-dialog modal-xl" }, [
                        _c("div", { staticClass: "modal-content" }, [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "h5",
                              {
                                staticClass: "modal-title",
                                attrs: { id: "exampleModalLabel" }
                              },
                              [
                                _vm._v(
                                  "\n                  Filters\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                  "aria-label": "Close"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { "aria-hidden": "true" } },
                                  [_vm._v("×")]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "div",
                              {
                                staticClass: "accordion",
                                attrs: { id: "accordionExample" }
                              },
                              [
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "certificationFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#certificationFilter",
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "certificationFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Certification\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "certificationFilter",
                                        "aria-labelledby":
                                          "certificationFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute:
                                                          "certification",
                                                        "transform-items":
                                                          _vm.transformCertification
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list certified-filter-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            item.label ==
                                                                            "Certified Pre-Owned"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Certified Pre-Owned"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : item.label ==
                                                                                "Clawson Certified Pre-Owned"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Clawson Certified Pre-Owned"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : item.label ==
                                                                                "Clawson Customs"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Clawson Customs"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : item.label ==
                                                                                "HondaTrue Used"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "HondaTrue Used"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : item.label ==
                                                                                "HondaTrue Certified"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "HondaTrue Certified"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : item.label ==
                                                                                "HondaTrue Certified+"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ais-RefinementList-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "HondaTrue Certified+"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "makeFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#makeFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "makeFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Make, Model & Trim\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "makeFilter",
                                        "aria-labelledby": "makeFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            staticClass: "mb-4",
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c("h6", [_vm._v("Make")])
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "make"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _c("hr", { staticClass: "divider" }),
                                          _vm._v(" "),
                                          _c("ais-panel", {
                                            staticClass: "mb-4",
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c("h6", [_vm._v("Model")])
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "model",
                                                        limit: 40
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _c("hr", { staticClass: "divider" }),
                                          _vm._v(" "),
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c("h6", [_vm._v("Trim")])
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "trim"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "priceFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#priceFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "priceFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Price\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "priceFilter",
                                        "aria-labelledby": "priceFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c(
                                            "asi-panel",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rangeInputWrapper"
                                                },
                                                [
                                                  _c("h6", [_vm._v("Price")]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ais-range-input",
                                                    {
                                                      attrs: {
                                                        attribute: "price"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "separator"
                                                          },
                                                          slot: "separator"
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("ais-range-input", {
                                                attrs: { attribute: "price" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var currentRefinement =
                                                        ref.currentRefinement
                                                      var range = ref.range
                                                      var refine = ref.refine
                                                      return _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c("vue-slider", {
                                                            ref: "slider2",
                                                            attrs: {
                                                              tooltip: "none",
                                                              min: range.min,
                                                              max: range.max,
                                                              lazy: false,
                                                              contained: true,
                                                              value: _vm.toValue(
                                                                currentRefinement,
                                                                range
                                                              ),
                                                              marks: [
                                                                range.min,
                                                                range.max
                                                              ],
                                                              railStyle: {
                                                                backgroundColor:
                                                                  "#dfdfdf"
                                                              },
                                                              processStyle: {
                                                                backgroundColor:
                                                                  "#0072ce"
                                                              },
                                                              labelStyle: {
                                                                marginTop:
                                                                  "1.25em",
                                                                color:
                                                                  "#939393",
                                                                fontSize:
                                                                  ".9rem"
                                                              },
                                                              enableCross: false
                                                            },
                                                            on: {
                                                              "drag-start": function(
                                                                $event
                                                              ) {
                                                                return _vm.updateSlider2()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return refine({
                                                                  min:
                                                                    $event[0],
                                                                  max: $event[1]
                                                                })
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "dot",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var focus =
                                                                      ref.focus
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class: [
                                                                            "custom-dot",
                                                                            {
                                                                              focus: focus
                                                                            }
                                                                          ]
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "bodyFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#bodyFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "bodyFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Body\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "bodyFilter",
                                        "aria-labelledby": "bodyFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "body"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "yearFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#yearFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "yearFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Year\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "yearFilter",
                                        "aria-labelledby": "yearFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c(
                                            "ais-panel",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rangeInputWrapper"
                                                },
                                                [
                                                  _c("h6", [_vm._v("Year")]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ais-range-input",
                                                    {
                                                      attrs: {
                                                        attribute: "year"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "separator"
                                                          },
                                                          slot: "separator"
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("ais-range-input", {
                                                attrs: { attribute: "year" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var currentRefinement =
                                                        ref.currentRefinement
                                                      var range = ref.range
                                                      var refine = ref.refine
                                                      return _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c("vue-slider", {
                                                            ref: "slider1",
                                                            attrs: {
                                                              tooltip: "none",
                                                              min: range.min,
                                                              max: range.max,
                                                              lazy: false,
                                                              contained: true,
                                                              value: _vm.toValue(
                                                                currentRefinement,
                                                                range
                                                              ),
                                                              marks: [
                                                                range.min,
                                                                range.max
                                                              ],
                                                              railStyle: {
                                                                backgroundColor:
                                                                  "#dfdfdf"
                                                              },
                                                              processStyle: {
                                                                backgroundColor:
                                                                  "#0072ce"
                                                              },
                                                              absorb: true,
                                                              labelStyle: {
                                                                marginTop:
                                                                  "1.25em",
                                                                color:
                                                                  "#939393",
                                                                fontSize:
                                                                  ".9rem"
                                                              },
                                                              enableCross: false
                                                            },
                                                            on: {
                                                              "drag-start": function(
                                                                $event
                                                              ) {
                                                                return _vm.updateSlider1()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return refine({
                                                                  min:
                                                                    $event[0],
                                                                  max: $event[1]
                                                                })
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "dot",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var focus =
                                                                      ref.focus
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class: [
                                                                            "custom-dot",
                                                                            {
                                                                              focus: focus
                                                                            }
                                                                          ]
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "fuelTypeFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#fuelTypeFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "fuelTypeFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Fuel Type\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "fuelTypeFilter",
                                        "aria-labelledby":
                                          "fuelTypeFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "fuel"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "mpgHWYFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#mpgHWYFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "mpgHWYFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          MPG (HWY)\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "mpgHWYFilter",
                                        "aria-labelledby": "mpgHWYFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c(
                                            "ais-panel",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rangeInputWrapper"
                                                },
                                                [
                                                  _c("h6", [
                                                    _vm._v("MPG (HWY)")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ais-range-input",
                                                    {
                                                      attrs: {
                                                        attribute: "highway_mpg"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "separator"
                                                          },
                                                          slot: "separator"
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("ais-range-input", {
                                                attrs: {
                                                  attribute: "highway_mpg"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var currentRefinement =
                                                        ref.currentRefinement
                                                      var range = ref.range
                                                      var refine = ref.refine
                                                      return _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c("vue-slider", {
                                                            ref: "slider3",
                                                            attrs: {
                                                              tooltip: "none",
                                                              min: range.min,
                                                              max: range.max,
                                                              lazy: false,
                                                              contained: true,
                                                              value: _vm.toValue(
                                                                currentRefinement,
                                                                range
                                                              ),
                                                              marks: [
                                                                range.min,
                                                                range.max
                                                              ],
                                                              railStyle: {
                                                                backgroundColor:
                                                                  "#dfdfdf"
                                                              },
                                                              processStyle: {
                                                                backgroundColor:
                                                                  "#0072ce"
                                                              },
                                                              absorb: true,
                                                              labelStyle: {
                                                                marginTop:
                                                                  "1.25em",
                                                                color:
                                                                  "#939393",
                                                                fontSize:
                                                                  ".9rem"
                                                              },
                                                              enableCross: false
                                                            },
                                                            on: {
                                                              "drag-start": function(
                                                                $event
                                                              ) {
                                                                return _vm.updateSlider3()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return refine({
                                                                  min:
                                                                    $event[0],
                                                                  max: $event[1]
                                                                })
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "dot",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var focus =
                                                                      ref.focus
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class: [
                                                                            "custom-dot",
                                                                            {
                                                                              focus: focus
                                                                            }
                                                                          ]
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "mpgCITYFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#mpgCITYFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "mpgCITYFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          MPG (CITY)\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "mpgCITYFilter",
                                        "aria-labelledby":
                                          "mpgCITYFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c(
                                            "ais-panel",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rangeInputWrapper"
                                                },
                                                [
                                                  _c("h6", [
                                                    _vm._v("MPG (CITY)")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ais-range-input",
                                                    {
                                                      attrs: {
                                                        attribute: "city_mpg"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "separator"
                                                          },
                                                          slot: "separator"
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("ais-range-input", {
                                                attrs: {
                                                  attribute: "city_mpg"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var currentRefinement =
                                                        ref.currentRefinement
                                                      var range = ref.range
                                                      var refine = ref.refine
                                                      return _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c("vue-slider", {
                                                            ref: "slider4",
                                                            attrs: {
                                                              tooltip: "none",
                                                              min: range.min,
                                                              max: range.max,
                                                              lazy: false,
                                                              contained: true,
                                                              value: _vm.toValue(
                                                                currentRefinement,
                                                                range
                                                              ),
                                                              marks: [
                                                                range.min,
                                                                range.max
                                                              ],
                                                              railStyle: {
                                                                backgroundColor:
                                                                  "#dfdfdf"
                                                              },
                                                              processStyle: {
                                                                backgroundColor:
                                                                  "#0072ce"
                                                              },
                                                              absorb: true,
                                                              labelStyle: {
                                                                marginTop:
                                                                  "1.25em",
                                                                color:
                                                                  "#939393",
                                                                fontSize:
                                                                  ".9rem"
                                                              },
                                                              enableCross: false
                                                            },
                                                            on: {
                                                              "drag-start": function(
                                                                $event
                                                              ) {
                                                                return _vm.updateSlider4()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return refine({
                                                                  min:
                                                                    $event[0],
                                                                  max: $event[1]
                                                                })
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "dot",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var focus =
                                                                      ref.focus
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class: [
                                                                            "custom-dot",
                                                                            {
                                                                              focus: focus
                                                                            }
                                                                          ]
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "featuresFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#featuresFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "featuresFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Features\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "featuresFilter",
                                        "aria-labelledby":
                                          "featuresFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute:
                                                          "feature_list",
                                                        "sort-by": [
                                                          "isRefined"
                                                        ],
                                                        limit: 8,
                                                        "show-more": true,
                                                        "show-more-limit": 50
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "driveTrainFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#driveTrainFilter",
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "driveTrainFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Drive Train\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "driveTrainFilter",
                                        "aria-labelledby":
                                          "driveTrainFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute:
                                                          "drivetrain_description"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "cylindersFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#cylindersFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "cylindersFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Cylinders\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "cylindersFilter",
                                        "aria-labelledby":
                                          "cylindersFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "cylinders"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "doorCountFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#doorCountFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "doorCountFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Door Count\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "doorCountFilter",
                                        "aria-labelledby":
                                          "doorCountFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "door_count"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "transmissionFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#transmissionFilter",
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "transmissionFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Transmission\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "transmissionFilter",
                                        "aria-labelledby":
                                          "transmissionFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute:
                                                          "transmission"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "engineFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#engineFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "engineFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Engine\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "engineFilter",
                                        "aria-labelledby": "engineFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "engine"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "InteriorMaterialHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#interiorMaterialFilter",
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "interiorMaterialFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Interior Material\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "interiorMaterialFilter",
                                        "aria-labelledby":
                                          "interiorMaterialHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute:
                                                          "interior_material"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing ot Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "card filter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header",
                                      attrs: { id: "colorFilterHeader" }
                                    },
                                    [
                                      _c("h2", { staticClass: "mb-0" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-block text-left collapsed",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "collapse",
                                              "data-target": "#colorFilter",
                                              "aria-expanded": "false",
                                              "aria-controls": "colorFilter"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Color\n\n                          "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "colorFilter",
                                        "aria-labelledby": "colorFilterHeader",
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body" },
                                        [
                                          _c("ais-panel", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hasRefinements =
                                                    ref.hasRefinements
                                                  return [
                                                    _c("ais-refinement-list", {
                                                      attrs: {
                                                        attribute: "color",
                                                        "transform-item":
                                                          _vm.transformColors
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var items =
                                                                ref.items
                                                              var refine =
                                                                ref.refine
                                                              var createURL =
                                                                ref.createURL
                                                              return _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "ais-RefinementList-list"
                                                                },
                                                                _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        staticClass:
                                                                          "ais-RefinementList-item",
                                                                        class: {
                                                                          "ais-RefinementList-item--selected":
                                                                            item.isRefined
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "ais-RefinementList-checkbox",
                                                                            style: {
                                                                              fontWeight: item.isRefined
                                                                                ? "bold"
                                                                                : ""
                                                                            },
                                                                            attrs: {
                                                                              href: createURL(
                                                                                item.value
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return refine(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ais-RefinementList-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    !hasRefinements
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Nothing to Filter"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "btnHolster" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            attrs: {
                                              "data-dismiss": "modal",
                                              "aria-label": "Close",
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Apply Filters\n                        "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row mb-2 mt-2 mt-sm-3 justify-content-between",
                    attrs: { id: "refineLabels" }
                  },
                  [
                    _c("ais-current-refinements", {
                      attrs: { "excluded-attributes": ["new"] },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var items = ref.items
                            var refine = ref.refine
                            var createURL = ref.createURL
                            return [
                              _c(
                                "ul",
                                { staticClass: "ais-currentRefinements-list" },
                                _vm._l(items, function(item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.attribute,
                                      staticClass:
                                        "ais-currentRefinements-attList"
                                    },
                                    _vm._l(item.refinements, function(
                                      refinement,
                                      index
                                    ) {
                                      return _c(
                                        "button",
                                        {
                                          key: [
                                            refinement.attribute,
                                            refinement.type,
                                            refinement.value,
                                            refinement.operator
                                          ].join(":"),
                                          staticClass:
                                            "ais-currentrefinements-delete ais-currentRefinements-item",
                                          attrs: {
                                            href: createURL(refinement)
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return refine(refinement)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ais-currentRefinements-label"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.transformCurrentRefinementLabel(
                                                      refinement
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                              index == 0 &&
                                              index + 1 ==
                                                item.refinements.length
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.getRefinementOperator(
                                                            refinement
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              index + 1 ==
                                              item.refinements.length
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ml-1" },
                                                    [
                                                      refinement.attribute ==
                                                      "highway_mpg"
                                                        ? _c("span", [
                                                            _vm._v("MPG (HWY)")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      refinement.attribute ==
                                                      "city_mpg"
                                                        ? _c("span", [
                                                            _vm._v("MPG (CITY)")
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                    X\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                }),
                                0
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row justify-content-between" },
                  [
                    _c("ais-stats", { ref: "statsRef" }),
                    _vm._v(" "),
                    _c(
                      "ais-clear-refinements",
                      {
                        attrs: {
                          "excluded-attributes": ["new"],
                          "class-names": {
                            "ais-ClearRefinements-button":
                              "clearFiltersBtn btn btn-sm btn-link p-0"
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "resetLabel" }, slot: "resetLabel" },
                          [_vm._v("Clear Filters")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("ais-state-results", {
                      staticClass: "w-100",
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var ref_results = ref.results
                            var hits = ref_results.hits
                            var query = ref_results.query
                            return [
                              hits.length > 0
                                ? _c("ais-hits", {
                                    ref: "hitsRef",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c("vehicle-card", {
                                                  attrs: {
                                                    carid: item.objectID,
                                                    car: item
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        }
                                      ],
                                      true
                                    )
                                  })
                                : _c("div", { staticClass: "mt-5" }, [
                                    _c("p", { staticClass: "h3" }, [
                                      _vm._v(
                                        "No results have been found for " +
                                          _vm._s(query)
                                      )
                                    ]),
                                    _vm._v("\n              .\n            ")
                                  ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("ais-pagination", { staticClass: "mt-4" })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "disclaimer-section container mt-5" }, [
              _c("p", { staticClass: "disclaimer row" }, [
                _vm._v(
                  "\n        *Based on 2021 EPA mileage ratings. Use for comparison purposes only.\n        Your mileage will vary depending on how you drive and maintain your\n        vehicle, driving conditions and other factors.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "disclaimer row" }, [
                _vm._v(
                  "\n        **Based on 2021 EPA mileage ratings. Use for comparison purposes only.\n        Your mileage will vary depending on how you drive and maintain your\n        vehicle, driving conditions, battery-pack age/condition (hybrid trims)\n        and other factors.\n      "
                )
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "disclaimer row" }, [
                _c("p", [
                  _vm._v(
                    "\n          New vehicle pricing includes all offers and incentives. Tax,\n          Title, and Tags are not included in vehicle prices shown and must\n          be paid by the purchaser. While great effort is made to ensure the\n          accuracy of the information on this site, errors do occur so\n          please verify information with a customer service rep. This is\n          easily done by calling us at 559-435-5000 or by visiting us at the\n          dealership.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          New and used prices do not include government fees, and taxes, any\n          finance charges, any dealer document processing charge, any\n          electronic filing charge, dealer installed accessories, and any\n          emission testing charge.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          **With approved credit. Terms may vary. Monthly payments are only\n          estimates derived from the vehicle price with a 72-month term,\n          4.9% interest, and 20% down payment.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Some of our used vehicles may be subject to unrepaired safety\n          recalls. Check for a vehicle’s unrepaired recalls by VIN at\n          "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "https://vinrcl.safecar.gov/vin/" } },
                    [_vm._v("Check for Recalls Here")]
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          All prices are plus government fees and taxes, any finance\n          charges, any dealer document processing charge, any electronic\n          filing charge, and any emissions testing charge.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for\n          comparison purposes only. Your mileage will vary depending on how\n          you drive and maintain your vehicle, driving conditions, and other\n          factors.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          *MPG for hybrid vehicles based on EPA mileage ratings. Use for\n          comparison purposes only. Your mileage will vary depending on how\n          you drive and maintain your vehicle, driving conditions,\n          battery-pack age/condition (hybrid trims), and other factors.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Any image of a stock photo may not reflect the model year or color\n          of, and/or equipment offered on, the advertised vehicle. The image\n          is shown for illustration purposes only.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Engine for Life Warranty covers all Clawson Certified Pre-Owned\n          units and is available for purchase on all current, plus 10 prior\n          model years under 100,000 miles. For more information go to\n          https://clawsonhonda.com/engine-life-warranty/\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Proposition 65 Warning: Operating, servicing, and maintaining a\n          passenger vehicle or off-road vehicle can expose you to chemicals\n          including engine exhaust, carbon monoxide, phthalates, and lead,\n          which are known to the State of California to cause cancer and\n          birth defects or other reproductive harm. To minimize exposure,\n          avoid breathing exhaust, do not idle the engine except as\n          necessary, service your vehicle in a well-ventilated area, and\n          wear gloves or wash your hands frequently when servicing your\n          vehicle. For more information go to\n          "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://www.p65warnings.ca.gov/products/passenger-vehicle/"
                      }
                    },
                    [_vm._v("\n            p65Warnings\n          ")]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }