var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "search-results-card", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { on: { click: _vm.goToVDP } },
        [
          _c("b-card-img", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              src: _vm.thumbnail,
              alt:
                _vm.car.year +
                " " +
                _vm.car.make +
                " " +
                _vm.car.model +
                " - image",
              top: ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "search-results-card-body" },
        [
          _c(
            "div",
            { staticClass: "details-disclaimer" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.details-disclaimer-modal",
                      modifiers: { "details-disclaimer-modal": true }
                    }
                  ],
                  staticClass: "show-details-disclaimer"
                },
                [_c("b-icon", { attrs: { icon: "question-circle" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "search-results-card-header-name",
                    on: { click: _vm.goToVDP }
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "search-results-card-header-name-bold" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.car.year) +
                            " " +
                            _vm._s(_vm.car.make) +
                            " " +
                            _vm._s(_vm.car.model) +
                            "\n              "
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.car.style) +
                              " " +
                              _vm._s(_vm.seriesDetailEval)
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "search-results-card-special-info" },
            [
              _c(
                "b-col",
                {
                  staticClass: "search-results-card-special-info--left",
                  attrs: { cols: "10" }
                },
                [
                  _c("div", { staticClass: "vin" }, [
                    _c("span", { staticClass: "vin-bold" }, [
                      _vm._v("\n              VIN:\n            ")
                    ]),
                    _vm._v(
                      "\n            " + _vm._s(_vm.car.vin) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "stock" }, [
                    _c("span", { staticClass: "stock-bold" }, [
                      _vm._v("\n              STOCK #:\n            ")
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.car.stock_number) +
                        "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "search-results-card-special-info--right",
                  attrs: { cols: "2" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "row align-items-center justify-content-around"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "linkIconWrapper" },
                        [
                          _c("b-icon", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.topright",
                                modifiers: { hover: true, topright: true }
                              }
                            ],
                            staticClass: "copyShareLinkIcon",
                            attrs: { title: "Share Vehicle", icon: "share" },
                            on: { click: _vm.copyShareLink }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "search-results-card-price" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "search-results-card-price-number" }, [
                  _vm.car.other_price &&
                  parseInt(_vm.car.other_price, 10) -
                    parseInt(_vm.car.price, 10) >
                    0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "search-results-card-price-number-price-ridge"
                        },
                        [
                          _c("div", { staticClass: "top" }, [
                            _c("span", { staticClass: "left pb-2" }, [
                              _c("span", { staticClass: "msrp" }, [
                                _vm._v("WAS")
                              ]),
                              _vm._v(
                                " $" +
                                  _vm._s(
                                    _vm._f("formatPrices")(_vm.car.other_price)
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "right" }, [
                              _c("span", { staticClass: "badge" }, [
                                _vm._v("- ")
                              ]),
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm._f("formatPrices")(
                                      parseInt(_vm.car.other_price, 10) -
                                        parseInt(_vm.car.price, 10)
                                    )
                                  )
                              ),
                              _c("span", { staticClass: "msrp noBadge" }, [
                                _vm._v("OFF")
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "bottom" }, [
                            _c("span", { staticClass: "salePrice" }, [
                              _c("span", { staticClass: "msrp" }, [
                                _vm._v("NOW")
                              ]),
                              _c("br"),
                              _vm._v("$"),
                              _c(
                                "span",
                                { staticClass: "msrp-dollar-number" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatPrices")(_vm.car.price)
                                    )
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm.car.price
                    ? _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "search-results-card-price-number-price"
                          },
                          [
                            _vm._v(
                              "\n                $" +
                                _vm._s(_vm._f("formatPrices")(_vm.car.price)) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    : _c(
                        "span",
                        { staticClass: "search-results-card-price-number" },
                        [
                          _vm._v(
                            "\n              Call For More Details\n            "
                          )
                        ]
                      )
                ])
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", {
                  staticClass: "flickFusionButton srp m-2",
                  class: { hover: _vm.ffBtnHover },
                  attrs: {
                    id: "ff_link",
                    ff_client: "8EDB5FF6-28AB-0AD6-2C2F-F7639EDD3F68",
                    ff_vin: _vm.car.vin
                  },
                  on: {
                    mouseover: function($event) {
                      _vm.ffBtnHover = true
                    },
                    mouseleave: function($event) {
                      _vm.ffBtnHover = false
                    }
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "search-results-card-details" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "search-results-card-details-int-color" },
                      [
                        _c("span", {
                          staticClass: "colorDot",
                          style: {
                            "background-color": _vm.transformColor(
                              _vm.car.interior_color
                            )
                          }
                        }),
                        _vm._v(
                          "\n              Interior: " +
                            _vm._s(_vm.car.interior_color) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search-results-card-details-ext-color" },
                      [
                        _c("span", {
                          staticClass: "colorDot",
                          style: {
                            "background-color": _vm.transformColor(
                              _vm.car.colour
                            )
                          }
                        }),
                        _vm._v(
                          "\n              Exterior: " +
                            _vm._s(_vm.car.colour) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search-results-card-details-mileage" },
                      [
                        _c("b-icon", {
                          staticClass:
                            "search-results-card-details-mileage-icon",
                          attrs: { icon: "speedometer2" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.car.highway_mpg
                                ? _vm.car.highway_mpg
                                    .toString(10)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "--"
                            ) +
                            " MPG HWY " +
                            _vm._s(_vm.asterisk) +
                            "\n            "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search-results-card-details-mileage" },
                      [
                        _c("b-icon", {
                          staticClass:
                            "search-results-card-details-mileage-icon",
                          attrs: { icon: "speedometer2" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.car.city_mpg
                                ? _vm.car.city_mpg
                                    .toString(10)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "--"
                            ) +
                            " MPG CITY " +
                            _vm._s(_vm.asterisk) +
                            "\n            "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search-results-card-details-mileage" },
                      [
                        _c("b-icon", {
                          staticClass:
                            "search-results-card-details-mileage-icon",
                          attrs: { icon: "speedometer" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.car.mileage
                                ? _vm.car.mileage
                                    .toString(10)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "--"
                            ) + " Miles"
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _vm.car.engine
                      ? _c(
                          "p",
                          { staticClass: "search-results-card-details-engine" },
                          [
                            _vm.car.style && _vm.car.style.includes("2.0T")
                              ? _c("span", [_vm._v("2.0T")])
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.car.engine) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.car.transmission
                      ? _c(
                          "p",
                          { staticClass: "search-results-card-details-trans" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.car.transmission) +
                                " Transmission\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.car.drivetrain_description
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "search-results-card-details-drive-train"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.car.drivetrain_description) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  staticClass:
                    "justify-content-start align-items-end badge-sash"
                },
                [
                  _vm.car.certification
                    ? _c(
                        "b-col",
                        {
                          class: [
                            _vm.car.certification
                              ? _vm.cPOBadgeMap[_vm.car.certification].class
                              : "",
                            "badge"
                          ],
                          attrs: { cols: "6" }
                        },
                        [
                          _c("img", {
                            staticClass: "cpoBadge",
                            attrs: {
                              loading: "lazy",
                              src: _vm.cPOBadgeMap[_vm.car.certification].src,
                              alt: _vm.cPOBadgeMap[_vm.car.certification].alt
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "dealRatingBadge", attrs: { cols: "6" } },
                    [
                      _c("span", {
                        attrs: {
                          "data-cg-vin": _vm.car.vin,
                          "data-cg-price": _vm.car.price
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("b-row", { staticClass: "justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "linkIconWrapper" },
                  [
                    _c(
                      "b-button",
                      { staticStyle: { margin: "auto" }, attrs: { pill: "" } },
                      [
                        _c("b-icon", {
                          staticClass: "checkLinkIcon",
                          attrs: { icon: "check-circle" }
                        }),
                        _vm._v(
                          "\n              Confirm Availability\n            "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-content-center justify-content-center mt-3" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "disclaimerText" }, [
                  _vm._v(
                    "\n            Stock images are for illustration purposes only\n          "
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-content-center justify-content-center mt-3" },
            [
              _c("div", {
                staticClass: "cn-button-container",
                attrs: {
                  "data-vin": _vm.car.vin,
                  "data-page-type": "srp",
                  "data-type": "used"
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "drivemotors",
                attrs: {
                  "data-ext-dealer-code": "2264",
                  "data-vin": _vm.car.vin,
                  "data-msrp": _vm.car.msrp,
                  "data-price": _vm.car.price,
                  "data-stock-code": _vm.car.stock_number,
                  "data-model-num": _vm.car.model,
                  "data-model-trim": _vm.car.trim,
                  "data-ext-color": _vm.car.colour,
                  "data-int-color": _vm.car.interior_color,
                  "data-is-new": 0,
                  "data-miles": _vm.car.odometer ? _vm.car.odometer : 0
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }